import { memo, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Modal, Form, Button } from 'antd';

import { Job, JobPayload } from '../../../../fsm/jobs';

import SkillsEducationStep from '../skills-education-step';

import styles from './Modal.module.css';

interface Props {
  data: Job;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (job: JobPayload) => void;
}

const SkillsEducationModal = ({
  data,
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      requiredSkills: data.requiredSkills,
      preferredSkills: data.preferredSkills,
      minTotalExperience: data.minTotalExperience,
      maxTotalExperience: data.maxTotalExperience,
      minIndustryExperience: data.minIndustryExperience,
      maxIndustryExperience: data.maxIndustryExperience,
      hasManagementExperience: data.hasManagementExperience,
      educationList: data.educationList,
      licensures: data.certification?.licensures || '',
      certifications: data.certification?.certifications || '',
    });
  }, [form, data]);

  const handleSave = useCallback(() => {
    onSubmit({
      ...data,
      requiredSkills: form.getFieldValue('requiredSkills') || null,
      preferredSkills: form.getFieldValue('preferredSkills') || null,
      minTotalExperience: form.getFieldValue('minTotalExperience') || null,
      maxTotalExperience: form.getFieldValue('maxTotalExperience') || null,
      minIndustryExperience:
        form.getFieldValue('minIndustryExperience') || null,
      maxIndustryExperience:
        form.getFieldValue('maxIndustryExperience') || null,
      hasManagementExperience:
        form.getFieldValue('hasManagementExperience') || null,
      educationList: form.getFieldValue('educationList') || null,
      certification: {
        licensures: form.getFieldValue('licensures') || null,
        certifications: form.getFieldValue('certifications') || null,
      },
    });

    onClose();
  }, [data, onSubmit, onClose]);

  return (
    <Modal
      title="Edit skills & experience"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={onClose}
      width={isMobile ? '100%' : '55%'}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <SkillsEducationStep form={form} />
        </div>
      </Form>
    </Modal>
  );
};

export default memo(SkillsEducationModal);

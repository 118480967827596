import { memo, useState, useRef, useEffect, useMemo } from 'react';
import { Input, Tag, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';

import styles from './index.module.css';

interface Props {
  value?: string[];
  label: string;
  limit?: number;
  onChange?: (values: string[]) => void;
}

const TagMaker = ({ value, label, limit, onChange }: Props) => {
  const [tags, setTags] = useState<string[]>(value ? value : []);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange && onChange(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      onChange && onChange([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag: string) => (
    <span key={tag} style={{ display: 'inline-block', fontSize: '16px' }}>
      <Tag
        closable
        className={styles.tag}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    </span>
  );

  const tagChild = tags.map(forMap);

  const disabled = useMemo(() => {
    if (!limit) {
      return false;
    }

    return tagChild.length < limit ? false : true;
  }, [limit, tagChild]);

  const title = useMemo(() => {
    if (!limit) {
      return label;
    }

    return tagChild.length < limit ? label : 'Add new';
  }, [limit, tagChild, label]);

  return (
    <div className={styles.wrapper}>
      {tagChild}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="large"
          value={inputValue}
          className={styles.input}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag
          onClick={showInput}
          className={disabled ? styles.addTagDisabled : styles.addTag}
        >
          <Space>
            <PlusOutlined />
            {title}
          </Space>
        </Tag>
      )}
    </div>
  );
};

export default memo(TagMaker);

import { memo, useCallback, useEffect, useState } from 'react';
import Layout from '../../../../components/layout';

import {
  Typography,
  Avatar,
  Space,
  Button,
  Form,
  Input,
  Upload,
  Flex,
  Spin,
  Select,
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  DeleteFilled,
  EditFilled,
} from '@ant-design/icons';

import styles from './index.module.css';
import { User } from '../../../../fsm/users';
import { useAccount } from '../../hooks/useAccount';
import { roleOptions } from '../../utils/role';

const { Title, Text, Link } = Typography;
const { Option } = Select;

interface Props {
  user?: User;
  isLoading: boolean;
  onRegisterImageUrl: (id: number) => void;
  onDeleteImageUrl: () => void;
  onUpdateUser: (data: Partial<User>, id: number, file?: File) => void;
}

const MyAccount = ({
  user,
  isLoading,
  onRegisterImageUrl,
  onDeleteImageUrl,
  onUpdateUser,
}: Props) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [file, setFile] = useState<File>();
  const [form] = Form.useForm();
  const { account } = useAccount();

  useEffect(() => {
    if (user) {
      setImageUrl(user?.profilePictureUrl || '');
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
      });
    }
  }, [user, form]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleUpload = (info: any) => {
    if (info.file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        // Set the image URL to display the preview
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(info.file);
    }
    setFile(info.file);
    account?.userId && onRegisterImageUrl(account.userId);
  };

  const handleDeleteImage = useCallback(() => {
    setImageUrl('');
    onDeleteImageUrl();
  }, [setImageUrl, onDeleteImageUrl]);

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();
      const firstName = form.getFieldValue('firstName');
      const lastName = form.getFieldValue('lastName');
      const email = form.getFieldValue('email');
      const role = form.getFieldValue('role');
      const calendarLinkUrl = user?.calendarLinkUrl;

      if (account?.userId) {
        onUpdateUser(
          { firstName, lastName, email, role, calendarLinkUrl },
          account.userId,
          file,
        );
      }
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [form, file, user, account?.userId, onUpdateUser]);

  return (
    <Layout user={user}>
      <div className={styles.header}>
        <Title level={5} style={{ margin: 0 }}>
          My profile
        </Title>
      </div>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 400 }}>
          <Spin tip="Loading..." />
        </Flex>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.form}>
              <Title level={5} style={{ marginBottom: 16 }}>
                Edit profile details
              </Title>
              <Form
                form={form}
                layout="vertical"
                style={{ width: '100%' }}
                autoComplete="off"
              >
                <Form.Item>
                  <Space size={16}>
                    <Avatar size={64} icon={<UserOutlined />} src={imageUrl} />
                    <Upload
                      accept=".png,.jpg,.jpeg,.pdf" // Restrict file types if necessary
                      showUploadList={false} // Hide default upload list if you don't want it
                      customRequest={handleUpload} // Handle upload manually
                    >
                      <Button
                        icon={imageUrl ? <EditFilled /> : <PlusOutlined />}
                      >
                        {imageUrl ? 'Change image' : 'Add image'}
                      </Button>
                    </Upload>
                    {(imageUrl || user?.profilePictureUrl) && (
                      <Button
                        type="text"
                        icon={
                          <Text type="danger" onClick={handleDeleteImage}>
                            <DeleteFilled />
                          </Text>
                        }
                      ></Button>
                    )}
                  </Space>
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                    { type: 'email' },
                  ]}
                >
                  <Input size="large" disabled={true} />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true }]}
                >
                  <Select size="large" disabled={true}>
                    {roleOptions.map((item, index) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleSave}>
                    Save changes
                  </Button>
                </Form.Item>
              </Form>
              <Typography>
                To modify profile access, please contact Avrio support at{' '}
                <Link>support@goavrio.com</Link> email.
              </Typography>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default memo(MyAccount);

import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Form, Button, Input } from 'antd';

import styles from './index.module.css';

const { TextArea } = Input;

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (jobId: number, candidateId: number, text: string) => void;
}

const AddFeedbackModal = ({ isOpen, isLoading, onClose, onSubmit }: Props) => {
  const [form] = Form.useForm();
  const { jobId, candidateId } = useParams();

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();
      jobId &&
        candidateId &&
        onSubmit(
          parseInt(jobId),
          parseInt(candidateId),
          form.getFieldValue('feedback'),
        );
      onClose();
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [jobId, candidateId, form, onClose, onSubmit]);

  return (
    <Modal
      title="Add feedback"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Add
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <Form.Item
            name="feedback"
            label="Add your feedback"
            rules={[{ required: true }]}
          >
            <TextArea showCount size="large" rows={4} maxLength={500} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(AddFeedbackModal);

import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Table, Row, Divider, Typography, Tag } from 'antd';
import type { TablePaginationConfig } from 'antd';

import Breadcrumb from '../../../../components/common/breadcrumb';
import Layout from '../../../../components/layout';
import { Candidate } from '../../../../fsm/jobs/jobs.fsm.types';
import styles from './index.module.css';
import { User } from '../../../../fsm/users';

const { Text } = Typography;

const STATUS_LABEL = {
  NEW: 'New',
  OPEN: 'Open',
  REVIEWED: 'Reviewed',
  INVITATION_SENT: 'Invitation sent',
  RIO_SCREENING: 'Rio screening',
  ALT_INTERVIEW: 'Alt interview',
  HM_REVIEW: 'HM review',
  REJECTED: 'Rejected',
  HIRED: 'Hired',
  NOT_INTERESTED: 'Not interested',
};

interface Props {
  user?: User;
  data: Candidate[];
  isLoading: boolean;
  totalElements: number;
  totalPages: number;
  onFetchSharedCandidates: (pageNumber: number, pageSize: number) => void;
}

const CandidatesShare = ({
  user,
  data,
  isLoading,
  totalElements,
  totalPages,
  onFetchSharedCandidates,
}: Props) => {
  const [pageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(10);

  useEffect(() => {
    onFetchSharedCandidates(pageNumber, pageSize);
  }, []);

  const list = useMemo(() => {
    return data.map((item) => ({ ...item, key: item.id }));
  }, [data]);

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      onFetchSharedCandidates(pagination.current as number, pageSize);
    },
    [pageSize, onFetchSharedCandidates],
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, row: Candidate) => (
        <Link to={`/jobs/${row.jobId}/candidates/${row.id}`}>{name}</Link>
      ),
    },
    {
      title: 'Job title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Fitscore',
      dataIndex: 'fitScore',
      key: 'fitScore',
      render: (fitscore: number) => (
        <div className={styles.card}>
          <div className={styles.cardScore}>{fitscore} </div>{' '}
          <Divider type="vertical" />
          <div className={styles.cardInfo}>8 Skill matches</div>
        </div>
      ),
    },
    {
      title: 'Last position',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let color;
        if (status === 'ACTIVE') {
          color = 'green';
        } else if (status === 'REJECTED' || status === 'NOT_INTERESTED') {
          color = 'red';
        } else if (status === 'NOT_REVIEWED') {
          color = undefined;
        } else {
          color = 'purple';
        }
        return (
          <Tag color={color}>
            {STATUS_LABEL[status as keyof typeof STATUS_LABEL]}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'listing',
      key: 'listing',
      render: (_a: unknown, { id, jobId }: Candidate) => (
        <Link to={`/jobs/${jobId}/candidates/${id}`}>View profile</Link>
      ),
    },
  ];

  return (
    <Layout user={user}>
      <Breadcrumb
        renderLeft={
          <Text strong style={{ fontSize: 16 }}>
            Candidates assessment
          </Text>
        }
      />
      <div className={styles.wrapper}>
        <Row align={'middle'} justify={'space-between'} style={{ padding: 8 }}>
          <div>
            <Text strong style={{ fontSize: 16 }}>
              Shared candidates
            </Text>
            <Divider type="vertical" />
            <Text type="secondary" style={{ fontSize: 16 }}>
              Total {totalElements}
            </Text>
          </div>
          <Text type="secondary">
            Below is the shortlist of candidates that Avrio AI and your
            recruitment team is recommending for the positions you are managing.
            You can view each candidate's annonymous profile, rate your level of
            interest and leave feedback/notes about each candidate.
          </Text>
        </Row>
        <Table
          dataSource={list}
          columns={columns}
          loading={isLoading}
          pagination={
            totalPages > 1 && {
              total: totalElements,
              pageSize: pageSize,
              showSizeChanger: false,
            }
          }
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
};

export default memo(CandidatesShare);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Button,
  Row,
  Typography,
  Space,
  Badge,
  Tag,
  Flex,
  Spin,
  Popconfirm,
} from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';

import Layout from '../../../../components/layout';
import Breadcrumb from '../../../../components/common/breadcrumb';
import JobDetailsEdit from '../../components/job-details-edit';
import SkillsEducationEdit from '../../components/skills-education-edit';
import InvitationSettingsEdit from '../../components/invitation-settings-edit';
import HiringManagerModal from '../../components/hiring-manager-modal';

import styles from './index.module.css';
import { Job, JobPayload, HiringManager } from '../../../../fsm/jobs';
import { usePermissions } from '../../../../context/permission.context';
import { User } from '../../../../fsm/users';

interface Props {
  user?: User;
  data: Job | null;
  isLoading: boolean;
  hiringManagers: HiringManager[];
  onFetchDetails: (id: number) => void;
  onFetchHiringManagers: () => void;
  onAssignHiringManagers: (id: number, hiringManagerId: number) => void;
  onUpdateDetails: (id: number, job: JobPayload) => void;
  onCandidateMatch: (jobId: number) => void;
}

const { Title } = Typography;

const aviroIntelligence = `${process.env.PUBLIC_URL}/images/aviro-intelligence.svg`;
const blueAI = `${process.env.PUBLIC_URL}/images/blue-ai.svg`;

const JobDetails = ({
  user,
  data,
  isLoading,
  hiringManagers,
  onFetchDetails,
  onUpdateDetails,
  onFetchHiringManagers,
  onAssignHiringManagers,
  onCandidateMatch,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isOpenStatusConfirmOpen, setIsOpenStatusConfirmOpen] = useState(false);
  const [isCloseStatusConfirmOpen, setIsCloseStatusConfirmOpen] =
    useState(false);
  const { jobId } = useParams();
  const { canSeeJobAssignHMCard, canSeeJobCandidateMatchButton } =
    usePermissions();

  useEffect(() => {
    if (jobId) {
      onFetchDetails(parseInt(jobId));
    }
  }, [jobId]);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleJobUpdate = useCallback(
    (job: JobPayload) => {
      if (jobId) {
        const payload: JobPayload = { ...job };
        delete payload.id;
        delete payload.externalId;
        delete payload.customer;
        delete payload.createdBy;
        delete payload.salaryCurrency;
        delete payload.visaRequirements;
        delete payload.statusChangedAt;
        delete payload.createdAt;
        delete payload.updatedAt;

        onUpdateDetails(parseInt(jobId), payload);
      }
    },
    [jobId, onUpdateDetails],
  );

  const handleCandidateMatch = useCallback(() => {
    if (jobId) {
      onCandidateMatch(parseInt(jobId));
    }
  }, [jobId, onCandidateMatch]);

  const showPopconfirm = useCallback(() => {
    setIsConfirmOpen(true);
  }, []);

  const handleOk = useCallback(() => {
    handleCandidateMatch();
    setIsConfirmOpen(false);
  }, [handleCandidateMatch]);

  const handleCancel = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);

  const showOpenStatusPopconfirm = useCallback(() => {
    setIsOpenStatusConfirmOpen(true);
  }, []);

  const handleOpenStatusCancel = useCallback(() => {
    setIsOpenStatusConfirmOpen(false);
  }, []);

  const showCloseStatusPopconfirm = useCallback(() => {
    setIsCloseStatusConfirmOpen(true);
  }, []);

  const handleCloseStatusCancel = useCallback(() => {
    setIsCloseStatusConfirmOpen(false);
  }, []);

  const handleUpdate = useCallback(
    (props: Partial<Job>) => () => {
      handleJobUpdate({
        ...data,
        ...props,
      });
      setIsCloseStatusConfirmOpen(false);
      setIsOpenStatusConfirmOpen(false);
    },
    [data, handleJobUpdate],
  );

  const ProductManagerCard = useMemo(() => {
    return (
      <div className={styles.productManagerCard}>
        <Space direction="vertical" size={0}>
          <Title level={2} style={{ marginTop: 0 }}>
            {data?.title}
          </Title>
          <Space size={20}>
            <Title
              type="secondary"
              style={{ fontSize: 14, fontWeight: 'normal' }}
            >
              Posted day:{' '}
              {data?.postedAt
                ? new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }).format(new Date((data?.postedAt as number) * 1000))
                : 'No date'}
            </Title>
            <Space>
              {data?.status === 'OPEN' && (
                <>
                  <Badge status="success" /> {'Open'}
                </>
              )}
              {data?.status === 'CLOSED' && (
                <>
                  <Badge status="error" /> {'Closed'}
                </>
              )}
            </Space>
          </Space>
        </Space>

        <Row justify={'end'}>
          <Space>
            <Button
              shape="round"
              onClick={handleUpdate({
                status: 'OPEN',
                postedAt: new Date().toISOString(),
              })}
            >
              Refresh
            </Button>
            {data?.status === 'OPEN' && (
              <Popconfirm
                title="Close job post"
                description="Are you sure you want to close the job post?"
                open={isCloseStatusConfirmOpen}
                onConfirm={handleUpdate({ status: 'CLOSED' })}
                onCancel={handleCloseStatusCancel}
              >
                <Button
                  type="text"
                  shape="round"
                  onClick={showCloseStatusPopconfirm}
                >
                  Close
                </Button>
              </Popconfirm>
            )}
            {data?.status === 'CLOSED' && (
              <Popconfirm
                title="Open job post"
                description="Are you sure you want to open the job post?"
                open={isOpenStatusConfirmOpen}
                onConfirm={handleUpdate({ status: 'OPEN' })}
                onCancel={handleOpenStatusCancel}
              >
                <Button
                  type="text"
                  shape="round"
                  onClick={showOpenStatusPopconfirm}
                >
                  Open
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Row>
      </div>
    );
  }, [data, isOpenStatusConfirmOpen, isCloseStatusConfirmOpen]);

  const intelligenceCard = useMemo(() => {
    return (
      <div className={styles.intelligenceCard}>
        <Space direction="vertical" size={12}>
          <Space>
            <img src={blueAI} />
            <img src={aviroIntelligence} />
            <InfoCircleOutlined size={24} style={{ color: '#00000073' }} />
          </Space>
          <Title
            type="secondary"
            style={{ fontSize: 14, marginBottom: 16, fontWeight: 'normal' }}
          >
            Experience and required skills highlighted by Avrio intelligence.
          </Title>
          <div>
            {data?.requiredSkills.map((item) => <Tag key={item}>{item}</Tag>)}
            {!!data?.minIndustryExperience && !!data.maxIndustryExperience && (
              <Tag>
                {data?.minIndustryExperience}-{data.maxIndustryExperience} years
                of industry experience
              </Tag>
            )}
          </div>
        </Space>

        <Row justify={'end'}>
          <Space>
            {canSeeJobCandidateMatchButton && (
              <Popconfirm
                title="Are you sure?"
                description="You want to start candidate match?"
                open={isConfirmOpen}
                okText="Yes"
                onConfirm={handleOk}
                onCancel={handleCancel}
              >
                <Button shape="round" onClick={showPopconfirm}>
                  Match new candidates
                </Button>
              </Popconfirm>
            )}
            <Button shape="round">
              <Link to="candidates">View candidates</Link>
            </Button>
          </Space>
        </Row>
      </div>
    );
  }, [data, isConfirmOpen]);

  return (
    <Layout user={user}>
      <Breadcrumb
        renderLeft={
          <Button type="text" icon={<ArrowLeftOutlined />}>
            <Link to="..">Back to job list</Link>
          </Button>
        }
      />
      {!data ? (
        <Flex align="center" justify="center" style={{ height: 400 }}>
          <Spin tip="Loading..." />
        </Flex>
      ) : (
        <>
          <div className={styles.header}>
            {ProductManagerCard}
            {intelligenceCard}
          </div>
          <div className={styles.hmCardWrapper}>
            <div className={styles.card}>
              {!data.hiringManagerName || !data.recruiterName ? (
                <>
                  <Typography style={{ maxWidth: 645 }}>
                    Add the Hiring manager to this job listing. Hiring managers
                    attached to the job will be visible only to you and the HR
                    Executive.
                  </Typography>
                  {canSeeJobAssignHMCard && (
                    <Button onClick={handleModalOpen}>
                      Add hiring manager
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Space size={4} direction="vertical">
                    <Space>
                      <Typography>Hiring manager</Typography>
                      <Typography.Text strong>
                        {data.hiringManagerName}
                      </Typography.Text>
                    </Space>
                    <Space>
                      <Typography>Recruiter</Typography>
                      <Typography.Text strong>
                        {data.recruiterName}
                      </Typography.Text>
                    </Space>
                  </Space>
                  {canSeeJobAssignHMCard && (
                    <Button onClick={handleModalOpen}>
                      Edit hiring manager
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <JobDetailsEdit data={data} onUpdateDetails={handleJobUpdate} />
          <SkillsEducationEdit data={data} onUpdateDetails={handleJobUpdate} />
          <InvitationSettingsEdit
            data={data}
            onUpdateDetails={handleJobUpdate}
          />
        </>
      )}
      <HiringManagerModal
        isOpen={isModalOpen}
        data={hiringManagers}
        isLoading={isLoading}
        hiringManagerId={data?.hiringManagerId}
        onClose={handleModalClose}
        onFetchHiringManagers={onFetchHiringManagers}
        onAssignHiringManagers={onAssignHiringManagers}
      />
    </Layout>
  );
};

export default JobDetails;

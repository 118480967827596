import { memo, useCallback, useMemo } from 'react';

import {
  Button,
  Form,
  InputNumber,
  Space,
  Divider,
  Row,
  Col,
  Checkbox,
} from 'antd';
import type { FormInstance } from 'antd';

import TagMaker from '../../../../components/common/tag-maker';
import EducationListMaker from '../../../../components/common/education-list-maker';

import { Step } from '../../types/index.types';

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  onStepChange?: (step: Step) => void;
}

const SkillsEducationStep = ({ form, onStepChange }: Props) => {
  const handleNext = useCallback(() => {
    onStepChange && onStepChange(Step.InvitationSettings);
  }, [onStepChange]);

  const handlePrev = useCallback(() => {
    onStepChange && onStepChange(Step.JobDetails);
  }, [onStepChange]);

  const handleRequiredSkillsChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        requiredSkills: value,
      });
    },
    [form],
  );

  const handlePreferredSkillsChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        preferredSkills: value,
      });
    },
    [form],
  );

  const handleCertificationsChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        certifications: value,
      });
    },
    [form],
  );

  const handleLicensuresChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        licensures: value,
      });
    },
    [form],
  );

  const handleEducationListChange = useCallback(
    (value: { academicDegree: string; major: string }[]) => {
      form.setFieldsValue({
        educationList: value,
      });
    },
    [form],
  );

  const skills = useMemo(() => {
    return (
      <>
        <Form.Item
          name="requiredSkills"
          label="Required skills"
          tooltip="Lorem Ipsum"
        >
          <TagMaker
            label={'Add required skill'}
            limit={5}
            value={form.getFieldValue('requiredSkills')}
            onChange={handleRequiredSkillsChange}
          />
        </Form.Item>
        <Form.Item
          name="preferredSkills"
          label="Preferred skills"
          tooltip="Lorem Ipsum"
        >
          <TagMaker
            label={'Add preferred skill'}
            limit={5}
            value={form.getFieldValue('preferredSkills')}
            onChange={handlePreferredSkillsChange}
          />
        </Form.Item>
      </>
    );
  }, []);

  const experience = useMemo(() => {
    return (
      <>
        <Form.Item name="totalExperience" label="Total experience">
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="minTotalExperience" style={{ marginBottom: 0 }}>
                <InputNumber
                  placeholder="Min"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="maxTotalExperience" style={{ marginBottom: 0 }}>
                <InputNumber
                  placeholder="Max"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="industryExperience" label="Industry experience">
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="minIndustryExperience"
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  placeholder="Min"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="maxIndustryExperience"
                style={{ marginBottom: 0 }}
              >
                <InputNumber
                  placeholder="Max"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="hasManagementExperience" valuePropName="checked">
          <Checkbox>Has management experience</Checkbox>
        </Form.Item>
      </>
    );
  }, []);

  const education = useMemo(() => {
    return (
      <>
        <Form.Item name="educationList">
          <EducationListMaker
            value={form.getFieldValue('educationList')}
            onChange={handleEducationListChange}
          />
        </Form.Item>
        <Form.Item name="certifications" label="Certifications">
          <TagMaker
            label={'Add certifications'}
            limit={5}
            onChange={handleCertificationsChange}
          />
        </Form.Item>
        <Form.Item name="licensures" label="Licensures">
          <TagMaker
            label={'Add licensures'}
            limit={5}
            onChange={handleLicensuresChange}
          />
        </Form.Item>
      </>
    );
  }, []);

  return (
    <>
      <Divider>Skills</Divider>
      {skills}
      <Divider>Experience</Divider>
      {experience}
      <Divider>Education</Divider>
      {education}
      {onStepChange && (
        <Form.Item>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              type="primary"
              size="large"
              block
              shape="round"
              onClick={handleNext}
            >
              Next
            </Button>
            <Button
              size="large"
              type="text"
              block
              shape="round"
              onClick={handlePrev}
            >
              Previous
            </Button>
          </Space>
        </Form.Item>
      )}
    </>
  );
};

export default memo(SkillsEducationStep);

import { setup, fromPromise, assign } from 'xstate';
import {
  Context,
  Event,
  State,
  EventType,
  addUserEvent,
  updateUserEvent,
  deleteUserEvent,
  resetPasswordEvent,
  getUserEvent,
  registorImageUrlEvent,
  User,
} from './users.fsm.types';
import axios from '../../axiosConfig';
import axiosAPI from 'axios';
import { AxiosError } from 'axios';

const MACHINE_ID = 'UsersFSM';

export const usersFSM = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    fetchUsers: fromPromise(
      async ({
        input,
      }: {
        input: { pageNumber: number; pageSize: number };
      }) => {
        const { pageNumber, pageSize } = input;
        const res = await axios.get(
          `/api/users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        );
        return res;
      },
    ),
    fetchCustomers: fromPromise(async () => {
      const res = await axios.get(`/api/customers`);
      return res;
    }),
    deleteUser: fromPromise(
      async ({
        input,
      }: {
        input: {
          id: number;
        };
      }) => {
        const { id } = input;
        const res = await axios.delete(`/api/users/${id}`);
        return res;
      },
    ),
    updateUser: fromPromise(
      async ({
        input,
      }: {
        input: {
          data: Partial<User>;
          id: number;
          imageUrl?: string;
          file?: File;
          hasProfilePicture?: boolean;
        };
      }) => {
        let hasProfilePicture = input.hasProfilePicture;
        if (input.file && input.imageUrl) {
          hasProfilePicture = true;
          await axiosAPI.put(input.imageUrl, input.file, {
            headers: {
              'Content-Type': input.file.type,
            },
          });
        }

        const res = await axios.put(`/api/users/${input.id}`, {
          ...input.data,
          hasProfilePicture,
        });
        return res;
      },
    ),
    addUser: fromPromise(
      async ({
        input,
      }: {
        input: {
          firstName: string;
          lastName: string;
          email: string;
          role: string;
          customerId?: number;
        };
      }) => {
        const { firstName, lastName, email, role, customerId } = input;
        const res = await axios.post(`/api/users`, {
          firstName,
          lastName,
          email,
          role,
          customerId,
        });
        return res;
      },
    ),
    resetPasswordRequest: fromPromise(
      async ({ input }: { input: { email: string } }) => {
        const res = await axios.post('/api/auth/reset-password/request', {
          email: input.email,
        });
        return res;
      },
    ),
    getUser: fromPromise(async ({ input }: { input: { id: number } }) => {
      const res = await axios.get(`/api/users/${input.id}`);
      return res;
    }),
    registorImageUrl: fromPromise(
      async ({ input }: { input: { id: number } }) => {
        const res = await axios.get(
          `/api/users/${input.id}/profile-picture/presigned-url`,
        );
        return res;
      },
    ),
  },
}).createMachine({
  id: MACHINE_ID,
  initial: State.INITIAL,
  context: {
    pageNumber: 1,
    pageSize: 10,
    isLoading: false,
    users: [],
    user: undefined,
    customers: [],
    totalPages: 0,
    totalElements: 0,
    imageURL: '',
    makeUploadCall: false,
    error: undefined,
  },
  states: {
    [State.INITIAL]: {
      on: {
        [EventType.FETCH_USERS]: {
          target: State.FETCH_USERS,
          actions: assign(({ event }) => ({
            isLoading: true,
            pageNumber: event.payload.pageNumber,
          })),
        },
        [EventType.FETCH_CUSTOMERS]: {
          target: State.FETCH_CUSTOMERS,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.ADD_USER]: {
          target: State.ADD_USER,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.UPDATE_USER]: {
          target: State.UPDATE_USER,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.DELETE_USER]: {
          target: State.DELETE_USER,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.RESET_PASSWORD]: {
          target: State.RESET_PASSWORD,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.GET_USER]: {
          target: State.GET_USER,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.REGISTOR_IMAGE_URL]: {
          target: State.REGISTOR_IMAGE_URL,
        },
        [EventType.DELETE_IMAGE]: {
          actions: assign(({ context }) => ({
            imageURL: '',
            user: context.user && {
              ...context.user,
              hasProfilePicture: false,
              profilePictureUrl: '',
            },
          })),
        },
      },
    },
    [State.REGISTOR_IMAGE_URL]: {
      invoke: {
        src: 'registorImageUrl',
        input: ({ event }) => ({
          id: (event as registorImageUrlEvent).payload.id,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            imageURL: ({ event }) => event.output.data.url,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.GET_USER]: {
      invoke: {
        src: 'getUser',
        input: ({ event }) => ({
          id: (event as getUserEvent).payload.id,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            user: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.RESET_PASSWORD]: {
      invoke: {
        src: 'resetPasswordRequest',
        input: ({ event }) => ({
          email: (event as resetPasswordEvent).payload.email,
        }),
        onDone: {
          target: State.RESET_PASSWORD_DONE,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.DELETE_USER]: {
      invoke: {
        src: 'deleteUser',
        input: ({ event }) => ({
          id: (event as deleteUserEvent).payload.id,
        }),
        onDone: {
          target: State.FETCH_USERS,
          actions: assign({
            isLoading: false,
            users: ({ context }) => context.users,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.UPDATE_USER]: {
      invoke: {
        src: 'updateUser',
        input: ({ event, context }) => ({
          data: (event as updateUserEvent).payload.data,
          id: (event as updateUserEvent).payload.id,
          imageUrl: context.imageURL,
          file: (event as updateUserEvent).payload.file,
          hasProfilePicture: context.user?.hasProfilePicture,
        }),
        onDone: {
          target: State.USER_UPDATED,
          actions: assign({
            isLoading: false,
            user: ({ event }) => event.output.data,
            users: ({ event, context }) => {
              const user = event.output.data;
              return context.users.map((item) => {
                if (item.id === user.id) {
                  return user;
                }

                return item;
              });
            },
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_CUSTOMERS]: {
      invoke: {
        src: 'fetchCustomers',
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            customers: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.ADD_USER]: {
      invoke: {
        src: 'addUser',
        input: ({ event }) => ({
          firstName: (event as addUserEvent).payload.firstName,
          lastName: (event as addUserEvent).payload.lastName,
          email: (event as addUserEvent).payload.email,
          role: (event as addUserEvent).payload.role,
          customerId: (event as addUserEvent).payload.customerId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            users: ({ event, context }) => [
              event.output.data,
              ...context.users,
            ],
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_USERS]: {
      invoke: {
        src: 'fetchUsers',
        input: ({ context }) => ({
          pageNumber: context.pageNumber,
          pageSize: context.pageSize,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            users: ({ event }) => event.output.data.users,
            totalElements: ({ event }) => event.output.data.totalElements,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.RESET_PASSWORD_DONE]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.USER_UPDATED]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.ERROR]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
  },
});

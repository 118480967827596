import { setup, assign, fromPromise } from 'xstate';
import axios from '../../axiosConfig';
import {
  Context,
  Event,
  State,
  loginEvent,
  EventType,
  resetPasswordRequestEvent,
  resetPasswordEvent,
} from './auth.fsm.types';
import { AxiosError } from 'axios';

const MACHINE_ID = 'AuthFSM';

export const authFSM = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    authenticateUser: fromPromise(
      async ({ input }: { input: { email: string; password: string } }) => {
        const res = await axios.post('/api/auth/login', {
          email: input.email,
          password: input.password,
        });
        return res;
      },
    ),
    resetPasswordRequest: fromPromise(
      async ({ input }: { input: { email: string } }) => {
        const res = await axios.post('/api/auth/reset-password/request', {
          email: input.email,
        });
        return res;
      },
    ),
    resetPassword: fromPromise(
      async ({ input }: { input: { password: string; token: string } }) => {
        const res = await axios.post(
          '/api/auth/reset-password',
          {
            password: input.password,
          },
          {
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          },
        );
        return res;
      },
    ),
  },
}).createMachine({
  id: MACHINE_ID,
  initial: State.INITIAL,
  context: {
    isLoading: false,
    token: null,
    navigate: undefined,
    error: undefined,
  },
  states: {
    [State.INITIAL]: {
      on: {
        [EventType.LOGIN]: {
          target: State.AUTHENTICATING,
          actions: assign(({ event }) => ({
            isLoading: true,
            navigate: event.payload.navigate,
          })),
        },
        [EventType.RESET_PASSWORD_REQUEST]: {
          target: State.RESET_PASSWORD_REQUEST,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.RESET_PASSWORD]: {
          target: State.RESET_PASSWORD,
          actions: assign(({ event }) => ({
            isLoading: true,
            navigate: event.payload.navigate,
          })),
        },
      },
    },
    [State.RESET_PASSWORD]: {
      invoke: {
        src: 'resetPassword',
        input: ({ event }) => ({
          password: (event as resetPasswordEvent).payload.password,
          token: (event as resetPasswordEvent).payload.token,
        }),
        onDone: {
          target: State.PASSWORD_RESETED,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.RESET_PASSWORD_REQUEST]: {
      invoke: {
        src: 'resetPasswordRequest',
        input: ({ event }) => ({
          email: (event as resetPasswordRequestEvent).payload.email,
        }),
        onDone: {
          target: State.EMAIL_SENT,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.AUTHENTICATING]: {
      invoke: {
        src: 'authenticateUser',
        input: ({ event }) => ({
          email: (event as loginEvent).payload.email,
          password: (event as loginEvent).payload.password,
        }),
        onDone: {
          target: [State.AUTHENTICATED],
          actions: assign({
            isLoading: false,
            error: undefined,
            token: ({ event }) => {
              const token = event.output.data.token;
              localStorage.setItem('token', token);
              return token;
            },
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.AUTHENTICATED]: {
      on: {
        LOGOUT: {
          target: State.INITIAL,
          actions: assign({
            token: null,
          }),
        },
      },
      always: {
        target: State.INITIAL,
        actions: ({ context }) => {
          if (context.navigate) {
            context.navigate('/jobs');
          }
        },
      },
    },
    [State.PASSWORD_RESETED]: {
      always: {
        target: State.INITIAL,
        actions: ({ context }) => {
          if (context.navigate) {
            context.navigate('/auth/login');
          }
        },
      },
    },
    [State.EMAIL_SENT]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.ERROR]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
  },
});

import { useCallback, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { EventType, State, User } from '../../fsm/users';
import { useAccount } from '../../context/account.context';

import { message } from 'antd';

import Users from './pages/users';
import MyAccount from './pages/my-account';

const AuthRoutes = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { state, send } = useAccount();

  useEffect(() => {
    if (state.value.match(State.RESET_PASSWORD_DONE)) {
      messageApi.open({
        type: 'success',
        content: 'Password has been reset.',
      });
    }
  }, [state.value]);

  const handleFetchUsers = useCallback(
    (pageNumber: number) => {
      send({
        type: EventType.FETCH_USERS,
        payload: { pageNumber },
      });
    },
    [send],
  );

  const handleFetchCustomers = useCallback(() => {
    send({
      type: EventType.FETCH_CUSTOMERS,
    });
  }, [send]);

  const handleAddUser = useCallback(
    (
      firstName: string,
      lastName: string,
      email: string,
      role: string,
      customerId?: number,
    ) => {
      send({
        type: EventType.ADD_USER,
        payload: { firstName, lastName, email, role, customerId },
      });
    },
    [send],
  );

  const handleUpdateUser = useCallback(
    (data: Partial<User>, id: number, file?: File) => {
      send({
        type: EventType.UPDATE_USER,
        payload: { data, id, file },
      });
    },
    [send],
  );

  const handleDeleteUser = useCallback(
    (id: number) => {
      send({
        type: EventType.DELETE_USER,
        payload: { id },
      });
    },
    [send],
  );

  const handleResetPassword = useCallback(
    (email: string) => {
      send({
        type: EventType.RESET_PASSWORD,
        payload: { email },
      });
    },
    [send],
  );

  const handleRegisterImageUrl = useCallback(
    (id: number) => {
      send({
        type: EventType.REGISTOR_IMAGE_URL,
        payload: { id },
      });
    },
    [send],
  );

  const handleDeleteImageUrl = useCallback(() => {
    send({
      type: EventType.DELETE_IMAGE,
    });
  }, [send]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Users
              user={state.context.user}
              data={state.context.users}
              customers={state.context.customers}
              isLoading={state.context.isLoading}
              totalElements={state.context.totalElements}
              onFetchUsers={handleFetchUsers}
              onAddUser={handleAddUser}
              onUpdateUser={handleUpdateUser}
              onFetchCustomers={handleFetchCustomers}
              onDeleteUser={handleDeleteUser}
              onResetPassword={handleResetPassword}
            />
          }
        />
        <Route
          path="/my-account"
          element={
            <MyAccount
              user={state.context.user}
              isLoading={state.context.isLoading}
              onRegisterImageUrl={handleRegisterImageUrl}
              onDeleteImageUrl={handleDeleteImageUrl}
              onUpdateUser={handleUpdateUser}
            />
          }
        />
      </Routes>
      {contextHolder}
    </>
  );
};

export default AuthRoutes;

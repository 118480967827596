import { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Form, Button, Typography, Select } from 'antd';
import { HiringManager } from '../../../../fsm/jobs';

import styles from './index.module.css';

const { Text } = Typography;
const { Option } = Select;

interface Props {
  data: HiringManager[];
  isOpen: boolean;
  isLoading: boolean;
  hiringManagerId?: number;
  onClose: () => void;
  onFetchHiringManagers: () => void;
  onAssignHiringManagers: (id: number, hiringManagerId: number) => void;
}

const HiringManagerModal = ({
  isOpen,
  isLoading,
  data,
  hiringManagerId,
  onClose,
  onFetchHiringManagers,
  onAssignHiringManagers,
}: Props) => {
  const [form] = Form.useForm();
  const { jobId } = useParams();

  useEffect(() => {
    if (isOpen) {
      onFetchHiringManagers();
      if (hiringManagerId) {
        form.setFieldValue('hiringManagerId', hiringManagerId);
      }
    }
  }, [isOpen, hiringManagerId]);

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();
      jobId &&
        onAssignHiringManagers(
          parseInt(jobId),
          form.getFieldValue('hiringManagerId'),
        );
      onClose();
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [form, jobId, onClose, onAssignHiringManagers]);

  return (
    <Modal
      title="Select Hiring manager"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <Form.Item name="hiringManagerId" rules={[{ required: true }]}>
            <Select size="large">
              {data?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                >{`${item.firstName} ${item.lastName}`}</Option>
              ))}
            </Select>
          </Form.Item>
          <Text type="secondary">
            Hiring managers attached to the job will be visible only to you and
            the HR Executive. Hiring managers can review and provide feedback
            when you share the list of candidates with them.
          </Text>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(HiringManagerModal);

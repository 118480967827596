import { memo, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Row, Col, Space, Tag, Flex, Divider, Typography } from 'antd';
import { ProfileOutlined, EditOutlined } from '@ant-design/icons';

import styles from './index.module.css';
import { Job, JobPayload } from '../../../../fsm/jobs';
import DetailsModal from './Modal';

const { Title, Text } = Typography;

const grayAI = `${process.env.PUBLIC_URL}/images/gray-ai.svg`;

interface Props {
  data: Job;
  onUpdateDetails: (job: JobPayload) => void;
}

const JobDetailsEdit = ({ data, onUpdateDetails }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return (
    <div className={styles.body}>
      <Flex align="center" justify="space-between">
        <Space>
          <ProfileOutlined />
          <Title level={4} style={{ margin: 0 }}>
            Job details
          </Title>
        </Space>
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={handleModalOpen}
        >
          {isMobile ? '' : 'Edit job details'}
        </Button>
      </Flex>
      <Divider />
      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <Space direction="vertical">
            <Text type="secondary">Job title</Text>
            <Text>{data.title}</Text>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <Space direction="vertical">
            <Space>
              <Text type="secondary">Alternative job titles</Text>
              <img src={grayAI} />
            </Space>
            {!!data.altJobTitles.length ? (
              <span style={{ display: 'inline-block', fontSize: '16px' }}>
                {data.altJobTitles.map((item) => (
                  <Tag key={item} className={styles.tag}>
                    {item}
                  </Tag>
                ))}
              </span>
            ) : (
              '---'
            )}
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <Space direction="vertical">
            <Text type="secondary">Job description</Text>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </Text>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
              <Text type="secondary">Job details</Text>
              <img src={grayAI} />
            </Space>
            <div className={styles.detailsCardWrapper}>
              <div className={styles.detailsCard}>
                <Space direction="vertical" size={12}>
                  <Text type="secondary">Company name</Text>
                  <Text>{data.employerName || 'N/A'}</Text>
                </Space>
              </div>
              <div className={styles.detailsCard}>
                <Space direction="vertical" size={12}>
                  <Text type="secondary">Location</Text>
                  <Text>{data.location.address || 'N/A'}</Text>
                </Space>
              </div>
              <div className={styles.detailsCard}>
                <Space direction="vertical" size={12}>
                  <Text type="secondary">Employment type</Text>
                  <Text>
                    {!!data.workplaceTypes.length
                      ? data.workplaceTypes.map(
                          (item, index) =>
                            `${item}${data.workplaceTypes.length - 1 !== index ? ', ' : ''}`,
                        )
                      : 'N/A'}
                  </Text>
                </Space>
              </div>
              <div className={styles.detailsCard}>
                <Space direction="vertical" size={12}>
                  <Text type="secondary">Salary range</Text>
                  <Text>
                    {!data.minSalary && !data.maxSalary
                      ? 'N/A'
                      : `$${data.minSalary}-$${data.maxSalary}`}
                  </Text>
                </Space>
              </div>
            </div>
          </Space>
        </Col>
      </Row>
      <DetailsModal
        data={data}
        isOpen={isModalOpen}
        isLoading={false}
        onClose={handleModalClose}
        onSubmit={onUpdateDetails}
      />
    </div>
  );
};

export default memo(JobDetailsEdit);

import { memo, useCallback, useMemo, useState } from 'react';

import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Radio,
  Slider,
  InputNumber,
} from 'antd';
import type { FormInstance } from 'antd';
import { DollarOutlined } from '@ant-design/icons';

import TagMaker from '../../../../components/common/tag-maker';
import Editor from '../../../../components/editor';

import { Step, WorkplaceType } from '../../types/index.types';

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  onStepChange?: (step: Step) => void;
}

const JobDetailsStep = ({ form, onStepChange }: Props) => {
  const [salaryMin, setSalaryMin] = useState<number>(
    form.getFieldValue('minSalary') || 50000,
  );
  const [salaryMax, setSalaryMax] = useState<number>(
    form.getFieldValue('maxSalary') || 100000,
  );

  const handleNext = useCallback(async () => {
    try {
      await form.validateFields();
      onStepChange && onStepChange(Step.SkillsEducation);
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [form, onStepChange]);

  const handleSliderChange = useCallback(
    (value: number[]) => {
      setSalaryMin(value[0]);
      setSalaryMax(value[1]);
      form.setFieldsValue({
        minSalary: value[0],
        maxSalary: value[1],
      });
    },
    [setSalaryMin, setSalaryMax, form],
  );

  const handleSliderMinChange = useCallback(
    (value: number | null) => {
      setSalaryMin(value ? value : 0);
      form.setFieldsValue({
        minSalary: value ? value : 0,
      });
    },
    [setSalaryMin, form],
  );

  const handleSliderMaxChange = useCallback(
    (value: number | null) => {
      setSalaryMax(value ? value : 0);
      form.setFieldsValue({
        maxSalary: value ? value : 0,
      });
    },
    [setSalaryMax, form],
  );

  const handleEditorChange = useCallback(
    (value: string) => {
      form.setFieldsValue({
        description: value,
      });
    },
    [form],
  );

  const handleAltJobTitlesChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        altJobTitles: value,
      });
    },
    [form],
  );

  const title = useMemo(() => {
    return (
      <Form.Item name="title" label="Job title" rules={[{ required: true }]}>
        <Input size="large" />
      </Form.Item>
    );
  }, []);

  const alternativeJobTitles = useMemo(() => {
    return (
      <Form.Item
        name="altJobTitles"
        label="Alternative job titles"
        tooltip="Lorem Ipsum"
      >
        <TagMaker
          value={form.getFieldValue('altJobTitles')}
          limit={5}
          label={'Add new job title'}
          onChange={handleAltJobTitlesChange}
        />
      </Form.Item>
    );
  }, [form]);

  const description = useMemo(() => {
    return (
      <Form.Item
        name="description"
        label="Job description"
        rules={[{ required: true }]}
      >
        <Editor
          value={form.getFieldValue('description')}
          onChange={handleEditorChange}
        />
      </Form.Item>
    );
  }, []);

  const companyAndLocation = useMemo(() => {
    return (
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name="employerName" label="Company name">
            <Input size="large" placeholder="Company name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="address" label="Location">
            <Input size="large" placeholder="Address" />
          </Form.Item>
        </Col>
      </Row>
    );
  }, []);

  const employmentType = useMemo(() => {
    return (
      <Form.Item name="workplaceTypes" label="Employment type">
        <Radio.Group>
          <Radio value={WorkplaceType.HYBRID}>Hybrid</Radio>
          <Radio value={WorkplaceType.REMOTE}>Remote</Radio>
          <Radio value={WorkplaceType.ONSITE}>Onsite</Radio>
        </Radio.Group>
      </Form.Item>
    );
  }, [form]);

  const salaryrange = useMemo(() => {
    return (
      <Form.Item name="salaryRange" label="Salary range">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="minSalary" style={{ margin: 0 }}>
              <InputNumber
                placeholder="Min"
                prefix={<DollarOutlined />}
                value={salaryMin}
                max={1000000}
                min={0}
                size="large"
                style={{ width: '100%' }}
                onChange={handleSliderMinChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="maxSalary" style={{ margin: 0 }}>
              <InputNumber
                placeholder="Max"
                prefix={<DollarOutlined />}
                value={salaryMax}
                max={1000000}
                min={0}
                size="large"
                style={{ width: '100%' }}
                onChange={handleSliderMaxChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Slider
          range
          max={1000000}
          min={0}
          value={[salaryMin, salaryMax]}
          onChange={handleSliderChange}
        />
      </Form.Item>
    );
  }, [
    salaryMin,
    salaryMax,
    handleSliderMaxChange,
    handleSliderMinChange,
    handleSliderChange,
  ]);

  return (
    <>
      {title}
      {alternativeJobTitles}
      {description}
      {companyAndLocation}
      {employmentType}
      {salaryrange}
      {onStepChange && (
        <Form.Item>
          <Button
            type="primary"
            size="large"
            block
            shape="round"
            onClick={handleNext}
          >
            Next
          </Button>
        </Form.Item>
      )}
    </>
  );
};

export default memo(JobDetailsStep);

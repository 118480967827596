import { memo, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Typography, Steps, Button, Form } from 'antd';

import Breadcrumb from '../../../../components/common/breadcrumb';

import JobDetailsStep from '../../components/job-details-step';
import InvitationSettingsStep from '../../components/invitation-settings-step';
import SkillsEducationStep from '../../components/skills-education-step';
import Layout from '../../../../components/layout';
import { Step } from '../../types/index.types';
import styles from './index.module.css';
import { JobPayload } from '../../../../fsm/jobs';
import { User } from '../../../../fsm/users';

const { Title } = Typography;

interface Props {
  user?: User;
  isLoading: boolean;
  onCreateJob: (job: JobPayload) => void;
}

const breadcrumbItems = [
  {
    title: <Link to="..">Job list</Link>,
  },
  {
    title: 'Add job',
  },
];

const steps = [
  {
    title: 'Job Details',
  },
  {
    title: 'Skills & Education',
  },
  {
    title: 'Invitation Settings',
  },
];

const CreateJob = ({ isLoading, user, onCreateJob }: Props) => {
  const [step, setStep] = useState<number>(Step.JobDetails);
  const [canPost, setCanPost] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      fitScore: 65,
      updatedDays: 90,
      distance: 40,
      minSalary: 50000,
      maxSalary: 100000,
    });
  }, [form]);

  const onFinish = useCallback(() => {
    console.log('finish');
  }, []);

  const onFieldsChange = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (changedFields: any, allFields: any) => {
      if (
        changedFields[0].name[0] === 'title' ||
        changedFields[0].name[0] === 'description'
      ) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const title = allFields.find((item: any) => item.name[0] === 'title');
        const description = allFields.find(
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          (item: any) => item.name[0] === 'description',
        );
        setCanPost(title.value && description.value);
      }
    },
    [setCanPost],
  );

  const handleStepChange = useCallback(
    (step: Step) => {
      setStep(step);
      const element = document.getElementById('content');
      element?.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setStep],
  );

  const handleSaveJob = useCallback(() => {
    const job = {
      title: form.getFieldValue('title'),
      description: form.getFieldValue('description'),
      employerName: form.getFieldValue('employerName') || null,
      workplaceTypes: form.getFieldValue('workplaceTypes')
        ? [form.getFieldValue('workplaceTypes')]
        : null,
      altJobTitles: form.getFieldValue('altJobTitles') || null,
      location: {
        country: '',
        city: '',
        state: '',
        address: form.getFieldValue('address') || null,
        zipCode: '',
        latitude: 0,
        longitude: 0,
        radius: 0,
      },
      minSalary: form.getFieldValue('minSalary') || null,
      maxSalary: form.getFieldValue('maxSalary') || null,
      requiredSkills: form.getFieldValue('requiredSkills') || null,
      preferredSkills: form.getFieldValue('preferredSkills') || null,
      minTotalExperience: form.getFieldValue('minTotalExperience') || null,
      maxTotalExperience: form.getFieldValue('maxTotalExperience') || null,
      minIndustryExperience:
        form.getFieldValue('minIndustryExperience') || null,
      maxIndustryExperience:
        form.getFieldValue('maxIndustryExperience') || null,
      hasManagementExperience:
        form.getFieldValue('hasManagementExperience') || null,
      educationList: form.getFieldValue('educationList'),
      certification: {
        certifications: form.getFieldValue('certifications') || null,
        licensures: form.getFieldValue('licensures') || null,
      },
      invitationSettings: {
        fitScore: form.getFieldValue('fitScore') || null,
        updatedDays: form.getFieldValue('updatedDays') || null,
        distance: form.getFieldValue('distance') || null,
      },
    };

    onCreateJob(job);
  }, [form, onCreateJob]);

  return (
    <Layout user={user}>
      <Breadcrumb
        items={breadcrumbItems}
        renderRight={
          <Button
            type="primary"
            shape="round"
            disabled={!canPost}
            onClick={handleSaveJob}
            loading={isLoading}
          >
            Post job
          </Button>
        }
      />
      <div className={styles.contentWrapper}>
        <Title level={isMobile ? 5 : 3} className={styles.title}>
          Add job
        </Title>
        <div className={styles.formWrapper}>
          <Steps
            size="small"
            current={step}
            items={steps}
            type={isMobile ? 'inline' : 'default'}
          />
          <div className={styles.formInnerWrapper}>
            <Form
              form={form}
              layout="vertical"
              style={{ width: '100%' }}
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              autoComplete="off"
            >
              {step === 0 && (
                <JobDetailsStep form={form} onStepChange={handleStepChange} />
              )}
              {step === 1 && (
                <SkillsEducationStep
                  form={form}
                  onStepChange={handleStepChange}
                />
              )}
              {step === 2 && (
                <InvitationSettingsStep
                  isLoading={isLoading}
                  onStepChange={handleStepChange}
                  onSubmitForm={handleSaveJob}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default memo(CreateJob);

import { memo, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Modal, Form, Button } from 'antd';

import { Job, JobPayload } from '../../../../fsm/jobs';

import InvitationSettingsStep from '../invitation-settings-step';

import styles from './Modal.module.css';

interface Props {
  data: Job;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (job: JobPayload) => void;
}

const InvitationSettingsModal = ({
  data,
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      fitScore: data.invitationSettings.fitScore,
      updatedDays: data.invitationSettings.updatedDays,
      distance: data.invitationSettings.distance,
    });
  }, [form, data]);

  const handleSave = useCallback(() => {
    onSubmit({
      ...data,
      invitationSettings: {
        fitScore: form.getFieldValue('fitScore') || null,
        updatedDays: form.getFieldValue('updatedDays') || null,
        distance: form.getFieldValue('distance') || null,
      },
    });

    onClose();
  }, [data, onSubmit, onClose]);

  return (
    <Modal
      title="Edit invitation settings"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={onClose}
      width={isMobile ? '100%' : '55%'}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <InvitationSettingsStep isLoading={false} />
        </div>
      </Form>
    </Modal>
  );
};

export default memo(InvitationSettingsModal);

import { AxiosError } from 'axios';
import { WorkplaceType } from '../../smallApps/jobs/types/index.types';

export type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type JobPayload = NullablePartial<Job>;

export interface Education {
  academicDegree: string;
  major: string;
}

export interface Job {
  id: number;
  employerName: string;
  workplaceTypes: WorkplaceType[];
  externalId: string;
  title: string;
  altJobTitles: string[];
  recruiterName: string | null;
  hiringManagerName: string | null;
  hiringManagerId?: number;
  location: {
    country: string;
    city: string;
    state: string;
    address: string;
    zipCode: string;
    latitude: number;
    longitude: number;
    radius: number;
  };
  educationList: Education[];
  certification: {
    certifications: string[];
    licensures: string[];
  };
  invitationSettings: {
    fitScore: number;
    updatedDays: number;
    distance: number;
  };
  description: string;
  salaryRange: string;
  minSalary: number;
  maxSalary: number;
  requiredSkills: string[];
  preferredSkills: string[];
  minTotalExperience: number;
  maxTotalExperience: number;
  minIndustryExperience: number;
  maxIndustryExperience: number;
  hasManagementExperience: boolean;
  status: string;
  customer: {
    id: number;
    name: string;
    logoUrl: string;
  };
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    customer: {
      id: number;
      name: string;
      logoUrl: string;
    };
    lastLoginAt: string;
    createdAt: string;
    updatedAt: string;
  };
  salaryCurrency: string;
  visaRequirements: string[];
  statusChangedAt: string;
  postedAt: number | string;
  createdAt: string;
  updatedAt: string;
}

export interface RioMessage {
  convoId: null | string;
  isFromBot: boolean;
  msgId: string;
  msgTime: number;
  personId: string;
  positionId: number;
  questionId: null | string;
  service: string;
  text: string;
}

export interface Candidate {
  employerName: string;
  fitScore: number;
  id: number;
  jobTitle: string;
  lastActivityAt: number;
  name: string;
  status: string;
  email: string;
  phoneNumber: string;
  updatedAt: number;
  jobId: number;
  skills: string[];
  degrees: Array<{ academicDegree: string; major: string }>;
  experiences: Array<{
    jobTitle: string;
    id: number;
    startDate: number;
    endDate: number;
  }>;
  rioMessages: Array<RioMessage>;
  requiredSkills: Array<string>;
  preferredSkills: Array<string>;
  statusChangedAt: number;
}

export interface HiringManager {
  customerId: number;
  customerName: string;
  email: string;
  firstName: string;
  id: number;
  profilePictureUrl: null | string;
  lastName: string;
  role: string;
}

export interface CandidateFeedback {
  createdAt: number;
  hiringManagerName: string;
  profilePictureUrl: string | null;
  text: string;
}

export interface Context {
  isLoading: boolean;
  jobList: Array<Job>;
  totalJobElements: number;
  totalCandidateElements: number;
  totalCandidatePages: number;
  totalJobPages: number;
  totalSharedCandidatePages: number;
  totalSharedCandidateElements: number;
  error?: AxiosError;
  jobDetails: Job | null;
  candidates: Candidate[];
  sharedCandidates: Candidate[];
  hiringManagers: HiringManager[];
  candidate?: Candidate;
  candidateFeedback: CandidateFeedback | null;
  candidateDownloadCVUrl: string;
}

export enum Status {
  NEW = 'NEW',
  REVIEWED = 'REVIEWED',
  INVITATION_SENT = 'INVITATION_SENT',
  RIO_SCREENING = 'RIO_SCREENING',
  ALT_INTERVIEW = 'ALT_INTERVIEW',
  HM_REVIEW = 'HM_REVIEW',
  REJECTED = 'REJECTED',
  HIRED = 'HIRED',
  NOT_INTERESTED = 'NOT_INTERESTED',
}

export enum State {
  INITIAL = 'idle',
  FETCH_JOB_LIST = 'FETCH_JOB_LIST',
  FETCH_JOB_BY_ID = 'FETCH_JOB_BY_ID',
  JOB_ADDED = 'JOB_ADDED',
  JOB_UPDATED = 'JOB_UPDATED',
  EMAIL_SENT = 'EMAIL_SENT',
  CREATE_JOB = 'CREATE_JOB',
  UPDATE_JOB = 'UPDATE_JOB',
  CANDIDATE_SHARED = 'CANDIDATE_SHARED',
  FETCH_CANDIDATES = 'FETCH_CANDIDATES',
  FETCH_SHARED_CANDIDATES = 'FETCH_SHARED_CANDIDATES',
  SEND_CANDIDATES = 'SEND_CANDIDATES',
  SHARE_CANDIDATE = 'SHARE_CANDIDATE',
  FETCH_HIRING_MANAGERS = 'FETCH_HIRING_MANAGERS',
  FETCH_JOB_HIRING_MANAGERS = 'FETCH_JOB_HIRING_MANAGERS',
  ASSIGN_HIRING_MANAGER = 'ASSIGN_HIRING_MANAGER',
  GET_CANDIDATE = 'GET_CANDIDATE',
  ADD_CANDIDATE_FEEDBACK = 'ADD_CANDIDATE_FEEDBACK',
  GET_CANDIDATE_FEEDBACK = 'GET_CANDIDATE_FEEDBACK',
  UPDATE_CANDIDATE_STATUS = 'UPDATE_CANDIDATE_STATUS',
  GET_CANDIDATE_DOWNLOAD_CV_URL = 'GET_CANDIDATE_DOWNLOAD_CV_URL',
  START_CANDIDATE_MATCHING = 'START_CANDIDATE_MATCHING',
  CANDIDATE_MACHED = 'CANDIDATE_MACHED',
  ERROR = 'error',
}

export enum EventType {
  FETCH_JOB_LIST = 'FETCH_JOB_LIST',
  FETCH_JOB_BY_ID = 'FETCH_JOB_BY_ID',
  CREATE_JOB = 'CREATE_JOB',
  UPDATE_JOB = 'UPDATE_JOB',
  FETCH_CANDIDATES = 'FETCH_CANDIDATES',
  FETCH_SHARED_CANDIDATES = 'FETCH_SHARED_CANDIDATES',
  SEND_CANDIDATES = 'SEND_CANDIDATES',
  SHARE_CANDIDATE = 'SHARE_CANDIDATE',
  FETCH_HIRING_MANAGERS = 'FETCH_HIRING_MANAGERS',
  FETCH_JOB_HIRING_MANAGERS = 'FETCH_JOB_HIRING_MANAGERS',
  ASSIGN_HIRING_MANAGER = 'ASSIGN_HIRING_MANAGER',
  GET_CANDIDATE = 'GET_CANDIDATE',
  ADD_CANDIDATE_FEEDBACK = 'ADD_CANDIDATE_FEEDBACK',
  GET_CANDIDATE_FEEDBACK = 'GET_CANDIDATE_FEEDBACK',
  UPDATE_CANDIDATE_STATUS = 'UPDATE_CANDIDATE_STATUS',
  GET_CANDIDATE_DOWNLOAD_CV_URL = 'GET_CANDIDATE_DOWNLOAD_CV_URL',
  START_CANDIDATE_MATCHING = 'START_CANDIDATE_MATCHING',
}

export type shareCandidateEvent = {
  type: EventType.SHARE_CANDIDATE;
  payload: { jobId: number; candidateId: number; userId: number };
};

export type startCandidateMatchingEvent = {
  type: EventType.START_CANDIDATE_MATCHING;
  payload: { jobId: number };
};

export type getCandidateEvent = {
  type: EventType.GET_CANDIDATE;
  payload: { jobId: number; candidateId: number };
};

export type getCandidateDownloadCVUrlEvent = {
  type: EventType.GET_CANDIDATE_DOWNLOAD_CV_URL;
  payload: { jobId: number; candidateId: number };
};

export type updateCandidateStatusEvent = {
  type: EventType.UPDATE_CANDIDATE_STATUS;
  payload: { status: string; jobId: number; candidateId: number };
};

export type addFeedbackEvent = {
  type: EventType.ADD_CANDIDATE_FEEDBACK;
  payload: { jobId: number; candidateId: number; text: string };
};

export type getFeedbackEvent = {
  type: EventType.GET_CANDIDATE_FEEDBACK;
  payload: { jobId: number; candidateId: number };
};

export type sendCandidatesEvent = {
  type: EventType.SEND_CANDIDATES;
  payload: {
    jobId: number;
    candidateIds: React.Key[];
    emails: string[];
    emailBody: string;
  };
};

export type fetchJobHiringmanagersEvent = {
  type: EventType.FETCH_JOB_HIRING_MANAGERS;
  payload: { jobId: number };
};

export type fetchHiringmanagersEvent = {
  type: EventType.FETCH_HIRING_MANAGERS;
};

export type assignHiringmanagersEvent = {
  type: EventType.ASSIGN_HIRING_MANAGER;
  payload: { jobId: number; hiringManagerId: number };
};

export type fetchJobListEvent = {
  type: EventType.FETCH_JOB_LIST;
  payload: {
    pageNumber: number;
    pageSize: number;
    sort?: string;
    order?: string;
    status?: string;
  };
};

export type fetchCandidatesEvent = {
  type: EventType.FETCH_CANDIDATES;
  payload: {
    jobId: number;
    pageNumber: number;
    pageSize: number;
    fitScore?: number;
    statuses?: Status[];
    hasMandatorySkills?: boolean;
    hasMandatoryTitleMatch?: boolean;
    maxDistance?: number;
    candidatesWithNoLocation?: boolean;
    isApplicant?: boolean;
    sort?: string;
    order?: string;
  };
};

export type fetchSharedCandidatesEvent = {
  type: EventType.FETCH_SHARED_CANDIDATES;
  payload: { pageNumber: number; pageSize: number };
};

export type createJobEvent = {
  type: EventType.CREATE_JOB;
  payload: { job: JobPayload };
};

export type fetchJobByIdEvent = {
  type: EventType.FETCH_JOB_BY_ID;
  payload: { id: number };
};

export type updateJobEvent = {
  type: EventType.UPDATE_JOB;
  payload: { id: number; job: JobPayload };
};

export type Event =
  | fetchJobListEvent
  | createJobEvent
  | fetchJobByIdEvent
  | updateJobEvent
  | fetchCandidatesEvent
  | sendCandidatesEvent
  | fetchHiringmanagersEvent
  | assignHiringmanagersEvent
  | getCandidateEvent
  | addFeedbackEvent
  | getFeedbackEvent
  | fetchJobHiringmanagersEvent
  | fetchSharedCandidatesEvent
  | updateCandidateStatusEvent
  | getCandidateDownloadCVUrlEvent
  | shareCandidateEvent
  | startCandidateMatchingEvent;

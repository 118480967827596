import { memo, useCallback, useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import Breadcrumb from '../../../../components/common/breadcrumb';

import { Typography, Form, Input, Button, Flex, Spin } from 'antd';

import { User } from '../../../../fsm/users';
import styles from './index.module.css';

const { Text } = Typography;

interface Props {
  user?: User;
  isLoading: boolean;
  onUpdateUser: (data: Partial<User>, id: number) => void;
}

const Calendar = ({ user, isLoading, onUpdateUser }: Props) => {
  const [disabled, setDisabled] = useState(Boolean(user?.calendarLinkUrl));
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        link: user.calendarLinkUrl,
      });
    }
  }, [user, form]);

  useEffect(() => {
    if (user?.calendarLinkUrl) {
      setDisabled(true);
    }
  }, [user?.calendarLinkUrl]);

  const handleEdit = useCallback(() => {
    setDisabled(false);
  }, [setDisabled]);

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();
      const calendarLinkUrl = form.getFieldValue('link');

      if (user?.id) {
        onUpdateUser(
          {
            calendarLinkUrl,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
          },
          user?.id,
        );
      }
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [form, user?.id, onUpdateUser]);

  return (
    <Layout user={user}>
      <Breadcrumb
        renderLeft={
          <Text strong style={{ fontSize: 16 }}>
            Calendar
          </Text>
        }
      />
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 400 }}>
          <Spin tip="Loading..." />
        </Flex>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <Text strong style={{ fontSize: 16 }}>
              Insert the scheduling link from your calendar app so Rio can
              schedule interviews with candidates.
            </Text>
            <Form
              form={form}
              layout="vertical"
              style={{ width: '100%' }}
              autoComplete="off"
            >
              <Form.Item
                name="link"
                label="Calendar link"
                rules={[{ required: true }]}
                style={{ paddingTop: 20 }}
              >
                <Input
                  placeholder="Calendar link"
                  size="large"
                  disabled={disabled}
                />
              </Form.Item>
              {disabled ? (
                <Button type="primary" onClick={handleEdit}>
                  Edit
                </Button>
              ) : (
                <Button type="primary" onClick={handleSave}>
                  Save
                </Button>
              )}
            </Form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default memo(Calendar);

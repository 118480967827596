import { memo } from 'react';
import { Navigate } from 'react-router-dom';

import { PermissionProvider } from '../../context/permission.context';
import { AccountProvider } from '../../context/account.context';
import { useAccount } from '../../smallApps/users/hooks/useAccount';

interface Props {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const { account } = useAccount();

  return (
    <>
      {isAuthenticated ? (
        <AccountProvider>
          <PermissionProvider role={account?.role}>
            {account?.role ? children : null}
          </PermissionProvider>
        </AccountProvider>
      ) : (
        <Navigate to={{ pathname: '/auth/login' }} replace />
      )}
    </>
  );
};

export default memo(PrivateRoute);

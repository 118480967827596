// PermissionContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import {
  UserRole,
  permissions,
  Permission,
} from '../smallApps/users/utils/role';

interface PermissionContextType {
  role: UserRole;
  canAddNewJob: boolean;
  canSeeJobCompanyColumn: boolean;
  canSeeJobRecruiterColumn: boolean;
  canSeeJobHiringManagerColumn: boolean;
  canSeeJobCustomerNameColumn: boolean;
  canSeeJobPostCustomerField: boolean;
  canSeeJobAssignHMCard: boolean;
  canSeeJobCandidateMatchButton: boolean;

  canSeeCandidateSendEmailButton: boolean;
  canSeeCandidateActionColumn: boolean;
  canSeeCandidateRejectButton: boolean;
  canSeeCandidateViewHMFeedbackButton: boolean;
  canSeeCandidateAddFeedbackButton: boolean;
  canSeeCandidateNextStageButton: boolean;

  canAddNewUser: boolean;
  canSeeAddUserCustomerField: boolean;
  canSeeUserCustomerColumn: boolean;
  canSeeUserActionsColumn: boolean;

  canSeeUserManagmentNavigationButton: boolean;
  canSeeMyProfileNavigationButton: boolean;
  canSeeCandidatesAssessmentNavigationButton: boolean;
}

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined,
);

export const PermissionProvider: React.FC<{
  role?: UserRole;
  children: ReactNode;
}> = ({ role, children }) => {
  const value = role && {
    role,
    canAddNewJob: permissions[role].includes(Permission.CAN_ADD_NEW_JOB),
    canSeeJobCompanyColumn: permissions[role].includes(
      Permission.CAN_SEE_JOB_COMPANY_COLUMN,
    ),
    canSeeJobRecruiterColumn: permissions[role].includes(
      Permission.CAN_SEE_JOB_RECRUITER_COLUMN,
    ),
    canSeeJobHiringManagerColumn: permissions[role].includes(
      Permission.CAN_SEE_JOB_HIRING_MANAGER_COLUMN,
    ),
    canSeeJobCustomerNameColumn: permissions[role].includes(
      Permission.CAN_SEE_JOB_CUSTOMER_NAME_COLUMN,
    ),
    canSeeJobPostCustomerField: permissions[role].includes(
      Permission.CAN_SEE_JOB_POST_CUSTOMER_FIELD,
    ),
    canSeeJobAssignHMCard: permissions[role].includes(
      Permission.CAN_SEE_JOB_ASSIGN_HM_CARD,
    ),
    canSeeJobCandidateMatchButton: permissions[role].includes(
      Permission.CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON,
    ),

    canSeeCandidateSendEmailButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON,
    ),
    canSeeCandidateActionColumn: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_ACTION_COLUMN,
    ),
    canSeeCandidateRejectButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_REJECT_BUTTON,
    ),
    canSeeCandidateViewHMFeedbackButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_VIEW_HM_FEEDBACK_BUTTON,
    ),
    canSeeCandidateAddFeedbackButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_ADD_FEEDBACK_BUTTON,
    ),
    canSeeCandidateNextStageButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATE_NEXT_STAGE_BUTTON,
    ),

    canAddNewUser: permissions[role].includes(Permission.CAN_ADD_NEW_USER),
    canSeeAddUserCustomerField: permissions[role].includes(
      Permission.CAN_SEE_ADD_USER_CUSTOMER_FIELD,
    ),
    canSeeUserCustomerColumn: permissions[role].includes(
      Permission.CAN_SEE_USER_CUSTOMER_COLUMN,
    ),
    canSeeUserActionsColumn: permissions[role].includes(
      Permission.CAN_SEE_USER_ACTIONS_COLUMN,
    ),

    canSeeUserManagmentNavigationButton: permissions[role].includes(
      Permission.CAN_SEE_USER_MANAGMENT_NAVIGATION_BUTTON,
    ),
    canSeeMyProfileNavigationButton: permissions[role].includes(
      Permission.CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON,
    ),
    canSeeCandidatesAssessmentNavigationButton: permissions[role].includes(
      Permission.CAN_SEE_CANDIDATES_ASSESSMENT_NAVIGATION_BUTTON,
    ),
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissions = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};

// PermissionContext.tsx
import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { StateFrom } from 'xstate';
import { jwtDecode } from 'jwt-decode';
import { User, usersFSM, EventType, Event } from '../fsm/users';

type MachineState = StateFrom<typeof usersFSM>;

interface AccountContextType {
  user?: User;
  state: MachineState;
  send: (event: Event) => void;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [state, send] = useMachine(usersFSM);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const decoded = token && jwtDecode<{ userId: number }>(token);
    const { userId } = decoded || {
      userId: 0,
    };
    if (userId) {
      send({
        type: EventType.GET_USER,
        payload: { id: userId },
      });
    }
  }, [send]);

  return (
    <AccountContext.Provider value={{ user: state.context.user, state, send }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = (): AccountContextType => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount error');
  }
  return context;
};

import { memo, useCallback, useState } from 'react';

import { Form, Select, Input, Button, Row, Tag, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import styles from './index.module.css';

const { Option } = Select;

interface Props {
  value?: { academicDegree: string; major: string }[];
  onChange?: (values: { academicDegree: string; major: string }[]) => void;
}

export const academicDegreeMapping = {
  'post-doctorate': 'Post doctorate',
  doctorate: 'Doctorate',
  master: 'Master’s',
  bachelor: 'Bachelor’s',
  associate: 'Associate’s',
  'high-school': 'High school / GED',
};

const EducationListMaker = ({ value, onChange }: Props) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [tags, setTags] = useState<{ academicDegree: string; major: string }[]>(
    value ? value : [],
  );
  const [form] = Form.useForm();

  const onFieldsChange = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (changedFields: any, allFields: any) => {
      if (
        changedFields[0].name[0] === 'academicDegree' ||
        changedFields[0].name[0] === 'major'
      ) {
        const academicDegree = allFields.find(
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          (item: any) => item.name[0] === 'academicDegree',
        );
        const major = allFields.find(
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          (item: any) => item.name[0] === 'major',
        );
        setDisabled(academicDegree.value || major.value ? false : true);
      }
    },
    [setDisabled],
  );

  const handleAddTags = useCallback(() => {
    const newObj = {
      academicDegree: form.getFieldValue('academicDegree') || '-',
      major: form.getFieldValue('major') || '-',
    };
    setTags([newObj, ...tags]);
    onChange && onChange([newObj, ...tags]);
    form.setFieldsValue({
      academicDegree: undefined,
      major: undefined,
    });
  }, [setTags, form, tags]);

  const handleClose = (removedTag: {
    academicDegree: string;
    major: string;
  }) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange && onChange(newTags);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '100%' }}
      autoComplete="off"
      onFieldsChange={onFieldsChange}
    >
      <div className={styles.tags}>
        {tags.map((item) => (
          <Tag
            closable
            style={{ marginBottom: 4 }}
            onClose={(e) => {
              e.preventDefault();
              handleClose(item);
            }}
          >
            {item.academicDegree} <Divider type="vertical" />
            {item.major}
          </Tag>
        ))}
      </div>

      <Form.Item name="academicDegree" label="Academic degree">
        <Select size="large" placeholder="Select academic degree">
          <Option value="post-doctorate">Post doctorate</Option>
          <Option value="doctorate">Doctorate</Option>
          <Option value="master">Master’s</Option>
          <Option value="bachelor">Bachelor’s</Option>
          <Option value="associate">Associate’s</Option>
          <Option value="high-school">High school / GED</Option>
        </Select>
      </Form.Item>
      <Form.Item name="major" label="Major" style={{ marginBottom: 4 }}>
        <Input size="large" />
      </Form.Item>
      <Row justify="end">
        <Button
          type="text"
          size="small"
          disabled={disabled}
          icon={<PlusOutlined />}
          onClick={handleAddTags}
        >
          Add new degree
        </Button>
      </Row>
    </Form>
  );
};

export default memo(EducationListMaker);

import { memo, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Form, Button, Input, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import styles from './index.module.css';

const { Title, Text } = Typography;

interface Props {
  isLoading: boolean;
  resetForm: boolean;
  onResetPassword: (password: string, token: string) => void;
  onResetPasswordRequest: (email: string) => void;
}

const SignIn = ({
  isLoading,
  resetForm,
  onResetPasswordRequest,
  onResetPassword,
}: Props) => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (resetForm) {
      form.setFieldValue('email', '');
    }
  }, [resetForm]);

  const onFinish = useCallback(() => {
    if (token) {
      onResetPassword(form.getFieldValue('password'), token);
    } else {
      onResetPasswordRequest(form.getFieldValue('email'));
    }
  }, [token, form, onResetPasswordRequest]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.leftInner}>
          <Title level={isMobile ? 2 : 1} style={{ marginBottom: 4 }}>
            Reset your password
          </Title>
          <Text type="secondary" style={{ marginBottom: 48 }}>
            {token
              ? 'Please enter a new password.'
              : 'Please enter your email address. You will receive a link to create a new password via email.'}
          </Text>
          <Form form={form} style={{ width: '100%' }} onFinish={onFinish}>
            {token ? (
              <>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters long!',
                    },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
                      message:
                        'Password must include uppercase, lowercase, number, and special character!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="New password" size="large" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters long!',
                    },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
                      message:
                        'Password must include uppercase, lowercase, number, and special character!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The new password that you entered do not match!',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm new password"
                    size="large"
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name',
                  },
                  { type: 'email' },
                ]}
              >
                <Input
                  placeholder="Email"
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={isLoading}
              >
                Reset password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.right}></div>
    </div>
  );
};

export default memo(SignIn);

import { useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { jobsFSM, EventType } from '../../fsm/jobs';

import CandidatesShare from './pages/candidates-share';
import { useAccount } from '../../context/account.context';

const JobsRoutes = () => {
  const [state, send] = useMachine(jobsFSM);
  const { user } = useAccount();

  const handleFetchSharedCandidates = useCallback(
    (pageNumber: number, pageSize: number) => {
      send({
        type: EventType.FETCH_SHARED_CANDIDATES,
        payload: { pageNumber, pageSize },
      });
    },
    [send],
  );

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <CandidatesShare
              user={user}
              data={state.context.sharedCandidates}
              totalElements={state.context.totalSharedCandidateElements}
              isLoading={state.context.isLoading}
              totalPages={state.context.totalSharedCandidatePages}
              onFetchSharedCandidates={handleFetchSharedCandidates}
            />
          }
        />
      </Routes>
    </>
  );
};

export default JobsRoutes;

import { memo, useEffect } from 'react';

import { Modal, Typography, Avatar, Row, Col, Space, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { CandidateFeedback } from '../../../../../fsm/jobs';

import styles from './index.module.css';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  feedback: CandidateFeedback | null;
  onClose: () => void;
  onGetFeedback: (jobId: number, candidateId: number) => void;
}

const ViewFeedbackModal = ({
  isOpen,
  isLoading,
  feedback,
  onClose,
  onGetFeedback,
}: Props) => {
  const { jobId, candidateId } = useParams();

  useEffect(() => {
    if (jobId && candidateId && isOpen) {
      onGetFeedback(parseInt(jobId), parseInt(candidateId));
    }
  }, [isOpen, jobId, candidateId, onGetFeedback]);

  return (
    <Modal
      title="Hiring manager feedback"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={null}
    >
      <div className={styles.content}>
        {!feedback ? (
          <Empty />
        ) : (
          <Row>
            <Col span={3}>
              <Avatar size={32} src={feedback?.profilePictureUrl}>
                {feedback?.hiringManagerName
                  .split(' ')
                  .map((item) => item.charAt(0))
                  .join('')}
              </Avatar>
            </Col>
            <Col span={21}>
              <Space direction="vertical">
                <Typography.Text strong>
                  {feedback && feedback?.hiringManagerName}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {feedback && feedback?.text}
                </Typography.Text>
                <Typography.Text>
                  {feedback?.createdAt &&
                    new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    }).format(new Date(feedback.createdAt * 1000))}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default memo(ViewFeedbackModal);

import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import styles from './index.module.css';

const { Title, Text } = Typography;

const src = `${process.env.PUBLIC_URL}/images/404.svg`;

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <img src={src} className={styles.img} />
      <Title level={3}>404 error</Title>
      <Text type="secondary" className={styles.desc}>
        Oops! The page you're looking for can't be found. It might have been
        moved or the link is incorrect. Please check the URL or return to the
        homepage.
      </Text>
      <Button type="primary">
        <Link to="jobs">Back to homepage</Link>
      </Button>
    </div>
  );
};

export default NotFound;

import { memo, useCallback, useEffect } from 'react';
import { Modal, Form, Button, Select, Input } from 'antd';
import { Customer, User } from '../../../../fsm/users';

import styles from './index.module.css';
import { roleOptions } from '../../utils/role';

const { Option } = Select;

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  customers: Customer[];
  user: User | null;
  canSeeAddUserCustomerField: boolean;
  onClose: () => void;
  onFetchCustomers: () => void;
  onUpdateUser: (data: Partial<User>, id: number) => void;
  onSubmit: (
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    customerId?: number,
  ) => void;
}

const AddUserModal = ({
  isOpen,
  isLoading,
  customers,
  user,
  canSeeAddUserCustomerField,
  onClose,
  onSubmit,
  onFetchCustomers,
  onUpdateUser,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen && canSeeAddUserCustomerField) {
      onFetchCustomers();
    }
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        customerId: user.customerId,
      });
    }
  }, [isOpen, canSeeAddUserCustomerField]);

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();
      const firstName = form.getFieldValue('firstName');
      const lastName = form.getFieldValue('lastName');
      const email = form.getFieldValue('email');
      const role = form.getFieldValue('role');
      const customerId = form.getFieldValue('customerId');
      if (user) {
        onUpdateUser({ firstName, lastName, email, role, customerId }, user.id);
      } else {
        onSubmit(firstName, lastName, email, role, customerId);
      }
      onClose();
      form.resetFields();
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [user, form, onSubmit, onClose, onUpdateUser]);

  return (
    <Modal
      title="Add new user"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={onClose}
      width={'520px'}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            {user ? 'Update' : 'Add'} user
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input your name',
              },
              { type: 'email' },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select size="large">
              {roleOptions.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {canSeeAddUserCustomerField && (
            <Form.Item
              name="customerId"
              label="Customer name"
              rules={[{ required: true }]}
            >
              <Select size="large">
                {customers.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default memo(AddUserModal);

import { memo, useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Modal, Form, Button } from 'antd';

import { Job, JobPayload } from '../../../../fsm/jobs';

import JobDetailsStep from '../job-details-step';

import styles from './Modal.module.css';

interface Props {
  data: Job;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (job: JobPayload) => void;
}

const DetailsModal = ({
  data,
  isOpen,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      title: data.title,
      description: data.description,
      altJobTitles: data.altJobTitles,
      employerName: data.employerName,
      address: data.location.address,
      workplaceTypes: data.workplaceTypes[0],
      minSalary: data.minSalary,
      maxSalary: data.maxSalary,
    });
  }, [form, data]);

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();

      onSubmit({
        ...data,
        title: form.getFieldValue('title'),
        description: form.getFieldValue('description'),
        altJobTitles: form.getFieldValue('altJobTitles') || null,
        employerName: form.getFieldValue('employerName') || null,
        location: {
          ...data.location,
          address: form.getFieldValue('address') || null,
        } as Job['location'],
        workplaceTypes: form.getFieldValue('workplaceTypes')
          ? [form.getFieldValue('workplaceTypes')]
          : null,
        minSalary: form.getFieldValue('minSalary') || null,
        maxSalary: form.getFieldValue('maxSalary') || null,
      });

      onClose();
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [data, onSubmit, onClose]);

  return (
    <Modal
      title="Edit job details"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={onClose}
      width={isMobile ? '100%' : '55%'}
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <JobDetailsStep form={form} />
        </div>
      </Form>
    </Modal>
  );
};

export default memo(DetailsModal);

import { setup, assign, fromPromise } from 'xstate';
import {
  Context,
  Event,
  State,
  EventType,
  createJobEvent,
  JobPayload,
  fetchJobListEvent,
  fetchJobByIdEvent,
  fetchCandidatesEvent,
  updateJobEvent,
  sendCandidatesEvent,
  assignHiringmanagersEvent,
  fetchJobHiringmanagersEvent,
  getCandidateEvent,
  addFeedbackEvent,
  getFeedbackEvent,
  fetchSharedCandidatesEvent,
  updateCandidateStatusEvent,
  getCandidateDownloadCVUrlEvent,
  shareCandidateEvent,
  Status,
  startCandidateMatchingEvent,
} from './jobs.fsm.types';
import axios from '../../axiosConfig';
import { AxiosError } from 'axios';

const MACHINE_ID = 'JobsFSM';

export const jobsFSM = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
    input: {} as { context: Context },
  },
  actors: {
    fetchJobList: fromPromise(
      async ({
        input,
      }: {
        input: {
          pageNumber: number;
          pageSize: number;
          sort?: string;
          order?: string;
          status?: string;
        };
      }) => {
        const { pageNumber, pageSize, sort, order, status } = input;
        const res = await axios.get(
          `/api/jobs?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=${sort}&order=${order}${status ? `&status=${status}` : ''}`,
        );
        return res;
      },
    ),
    createJob: fromPromise(
      async ({ input }: { input: { job: JobPayload } }) => {
        const res = await axios.post('/api/jobs', input.job);
        return res;
      },
    ),
    fetchJobById: fromPromise(async ({ input }: { input: { id: number } }) => {
      const res = await axios.get(`/api/jobs/${input.id}`);
      return res;
    }),
    updateJob: fromPromise(
      async ({ input }: { input: { id: number; job: JobPayload } }) => {
        const res = await axios.put(`/api/jobs/${input.id}`, input.job);
        return res;
      },
    ),
    fetchSharedCandidates: fromPromise(
      async ({
        input,
      }: {
        input: { pageNumber: number; pageSize: number };
      }) => {
        const res = await axios.get(
          `/api/shared-candidates?pageNumber=${input.pageNumber}&pageSize=${input.pageSize}`,
        );
        return res;
      },
    ),
    fetchCandidates: fromPromise(
      async ({
        input,
      }: {
        input: {
          jobId: number;
          pageNumber: number;
          pageSize: number;
          fitScore?: number;
          statuses?: Status[];
          hasMandatorySkills?: boolean;
          hasMandatoryTitleMatch?: boolean;
          maxDistance?: number;
          candidatesWithNoLocation?: boolean;
          isApplicant?: boolean;
          sort?: string;
          order?: string;
        };
      }) => {
        // Convert to a query string
        const queryFilterString = Object.entries({
          fitScore: input.fitScore,
          statuses: input.statuses,
          hasMandatorySkills: input.hasMandatorySkills,
          hasMandatoryTitleMatch: input.hasMandatoryTitleMatch,
          maxDistance: input.maxDistance,
          candidatesWithNoLocation: input.candidatesWithNoLocation,
          isApplicant: input.isApplicant,
          sort: input.sort,
          order: input.order,
        })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, value]) => value !== undefined)
          .map(
            ([key, value]) =>
              value &&
              `${encodeURIComponent(key)}=${Array.isArray(value) ? value.join(',') : encodeURIComponent(value)}`,
          )
          .join('&');
        const res = await axios.get(
          `/api/jobs/${input.jobId}/candidates?pageNumber=${input.pageNumber}&pageSize=${input.pageSize}&${queryFilterString}`,
        );
        return res;
      },
    ),
    fetchHiringManagers: fromPromise(async () => {
      const res = await axios.get(`/api/users?role=HIRING_MANAGER`);
      return res;
    }),
    fetchJobHiringManagers: fromPromise(
      async ({ input }: { input: { jobId: number } }) => {
        const res = await axios.get(`/api/jobs/${input.jobId}/hiring-managers`);
        return res;
      },
    ),
    assignHiringManagers: fromPromise(
      async ({
        input,
      }: {
        input: { jobId: number; hiringManagerId: number };
      }) => {
        const res = await axios.patch(
          `/api/jobs/${input.jobId}/hiring-managers`,
          { hiringManagerId: input.hiringManagerId },
        );
        return res;
      },
    ),
    getCandidate: fromPromise(
      async ({ input }: { input: { jobId: number; candidateId: number } }) => {
        const res = await axios.get(
          `/api/jobs/${input.jobId}/candidates/${input.candidateId}`,
        );
        return res;
      },
    ),
    addFeedback: fromPromise(
      async ({
        input,
      }: {
        input: { jobId: number; candidateId: number; text: string };
      }) => {
        const res = await axios.put(
          `/api/jobs/${input.jobId}/candidates/${input.candidateId}/feedbacks`,
          { text: input.text },
        );
        return res;
      },
    ),
    getFeedback: fromPromise(
      async ({ input }: { input: { jobId: number; candidateId: number } }) => {
        try {
          const res = await axios.get(
            `/api/jobs/${input.jobId}/candidates/${input.candidateId}/feedbacks`,
          );
          return res;
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error.request.status === 404) {
            return { data: null };
          }
          throw Error(error);
        }
      },
    ),
    sendCandidates: fromPromise(
      async ({
        input,
      }: {
        input: {
          jobId: number;
          candidateIds: React.Key[];
          emails: string[];
          emailBody: string;
        };
      }) => {
        const res = await axios.post(
          `/api/jobs/${input.jobId}/send-candidates`,
          {
            candidateIds: input.candidateIds,
            emails: input.emails,
            emailBody: input.emailBody,
          },
        );
        return res;
      },
    ),
    getCandidateDownloadCVUrl: fromPromise(
      async ({
        input,
      }: {
        input: {
          jobId: number;
          candidateId: number;
        };
      }) => {
        const res = await axios.get(
          `/api/jobs/${input.jobId}/candidates/${input.candidateId}/resume`,
        );
        return res;
      },
    ),
    updateCandidateStatus: fromPromise(
      async ({
        input,
      }: {
        input: {
          status: string;
          jobId: number;
          candidateId: number;
        };
      }) => {
        const res = await axios.patch(
          `/api/jobs/${input.jobId}/candidates/${input.candidateId}/status`,
          {
            status: input.status,
          },
        );
        return res;
      },
    ),
    shareCandidate: fromPromise(
      async ({
        input,
      }: {
        input: {
          jobId: number;
          candidateId: number;
          userId: number;
        };
      }) => {
        const res = await axios.patch(
          `/api/jobs/${input.jobId}/candidates/${input.candidateId}/send`,
          { userId: input.userId },
        );
        return res;
      },
    ),
    startCandidateMatching: fromPromise(
      async ({
        input,
      }: {
        input: {
          jobId: number;
        };
      }) => {
        const res = await axios.post(
          `/api/jobs/${input.jobId}/start-matching-candidates`,
        );
        return res;
      },
    ),
  },
}).createMachine({
  id: MACHINE_ID,
  initial: State.INITIAL,
  context: {
    isLoading: false,
    jobList: [],
    candidates: [],
    sharedCandidates: [],
    hiringManagers: [],
    totalJobElements: 0,
    totalCandidateElements: 0,
    totalCandidatePages: 0,
    totalJobPages: 0,
    totalSharedCandidatePages: 0,
    totalSharedCandidateElements: 0,
    candidateDownloadCVUrl: '',
    candidateFeedback: null,
    jobDetails: null,
    candidate: undefined,
    error: undefined,
  },

  states: {
    [State.INITIAL]: {
      on: {
        [EventType.FETCH_JOB_LIST]: {
          target: State.FETCH_JOB_LIST,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.CREATE_JOB]: {
          target: State.CREATE_JOB,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.FETCH_JOB_BY_ID]: {
          target: State.FETCH_JOB_BY_ID,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.UPDATE_JOB]: {
          target: State.UPDATE_JOB,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.FETCH_CANDIDATES]: {
          target: State.FETCH_CANDIDATES,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.SEND_CANDIDATES]: {
          target: State.SEND_CANDIDATES,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.FETCH_JOB_HIRING_MANAGERS]: {
          target: State.FETCH_JOB_HIRING_MANAGERS,
        },
        [EventType.FETCH_HIRING_MANAGERS]: {
          target: State.FETCH_HIRING_MANAGERS,
        },
        [EventType.ASSIGN_HIRING_MANAGER]: {
          target: State.ASSIGN_HIRING_MANAGER,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.GET_CANDIDATE]: {
          target: State.GET_CANDIDATE,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.ADD_CANDIDATE_FEEDBACK]: {
          target: State.ADD_CANDIDATE_FEEDBACK,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.GET_CANDIDATE_FEEDBACK]: {
          target: State.GET_CANDIDATE_FEEDBACK,
        },
        [EventType.FETCH_SHARED_CANDIDATES]: {
          target: State.FETCH_SHARED_CANDIDATES,
          actions: assign(() => ({
            isLoading: true,
          })),
        },
        [EventType.UPDATE_CANDIDATE_STATUS]: {
          target: State.UPDATE_CANDIDATE_STATUS,
        },
        [EventType.GET_CANDIDATE_DOWNLOAD_CV_URL]: {
          target: State.GET_CANDIDATE_DOWNLOAD_CV_URL,
        },
        [EventType.SHARE_CANDIDATE]: {
          target: State.SHARE_CANDIDATE,
        },
        [EventType.START_CANDIDATE_MATCHING]: {
          target: State.START_CANDIDATE_MATCHING,
        },
      },
    },
    [State.START_CANDIDATE_MATCHING]: {
      invoke: {
        src: 'startCandidateMatching',
        input: ({ event }) => ({
          jobId: (event as startCandidateMatchingEvent).payload.jobId,
        }),
        onDone: {
          target: State.CANDIDATE_MACHED,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.SHARE_CANDIDATE]: {
      invoke: {
        src: 'shareCandidate',
        input: ({ event }) => ({
          jobId: (event as shareCandidateEvent).payload.jobId,
          candidateId: (event as shareCandidateEvent).payload.candidateId,
          userId: (event as shareCandidateEvent).payload.userId,
        }),
        onDone: {
          target: State.CANDIDATE_SHARED,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.GET_CANDIDATE_DOWNLOAD_CV_URL]: {
      invoke: {
        src: 'getCandidateDownloadCVUrl',
        input: ({ event }) => ({
          jobId: (event as getCandidateDownloadCVUrlEvent).payload.jobId,
          candidateId: (event as getCandidateDownloadCVUrlEvent).payload
            .candidateId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            candidateDownloadCVUrl: ({ event }) => event.output.data.url,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.UPDATE_CANDIDATE_STATUS]: {
      invoke: {
        src: 'updateCandidateStatus',
        input: ({ event }) => ({
          status: (event as updateCandidateStatusEvent).payload.status,
          jobId: (event as updateCandidateStatusEvent).payload.jobId,
          candidateId: (event as updateCandidateStatusEvent).payload
            .candidateId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            candidate: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_SHARED_CANDIDATES]: {
      invoke: {
        src: 'fetchSharedCandidates',
        input: ({ event }) => ({
          pageNumber: (event as fetchSharedCandidatesEvent).payload.pageNumber,
          pageSize: (event as fetchSharedCandidatesEvent).payload.pageSize,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            sharedCandidates: ({ event }) => event.output.data.sharedCandidates,
            totalSharedCandidatePages: ({ event }) =>
              event.output.data.totalPages,
            totalSharedCandidateElements: ({ event }) =>
              event.output.data.totalElements,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.GET_CANDIDATE_FEEDBACK]: {
      invoke: {
        src: 'getFeedback',
        input: ({ event }) => ({
          jobId: (event as getFeedbackEvent).payload.jobId,
          candidateId: (event as getFeedbackEvent).payload.candidateId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            candidateFeedback: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.ADD_CANDIDATE_FEEDBACK]: {
      invoke: {
        src: 'addFeedback',
        input: ({ event }) => ({
          jobId: (event as addFeedbackEvent).payload.jobId,
          candidateId: (event as addFeedbackEvent).payload.candidateId,
          text: (event as addFeedbackEvent).payload.text,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.GET_CANDIDATE]: {
      invoke: {
        src: 'getCandidate',
        input: ({ event }) => ({
          jobId: (event as getCandidateEvent).payload.jobId,
          candidateId: (event as getCandidateEvent).payload.candidateId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            candidate: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.ASSIGN_HIRING_MANAGER]: {
      invoke: {
        src: 'assignHiringManagers',
        input: ({ event }) => ({
          jobId: (event as assignHiringmanagersEvent).payload.jobId,
          hiringManagerId: (event as assignHiringmanagersEvent).payload
            .hiringManagerId,
        }),
        onDone: {
          target: State.JOB_UPDATED,
          actions: assign({
            isLoading: false,
            jobDetails: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_HIRING_MANAGERS]: {
      invoke: {
        src: 'fetchHiringManagers',
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            hiringManagers: ({ event }) => event.output.data.users,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_JOB_HIRING_MANAGERS]: {
      invoke: {
        src: 'fetchJobHiringManagers',
        input: ({ event }) => ({
          jobId: (event as fetchJobHiringmanagersEvent).payload.jobId,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            hiringManagers: ({ event }) => event.output.data.users,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.SEND_CANDIDATES]: {
      invoke: {
        src: 'sendCandidates',
        input: ({ event }) => ({
          jobId: (event as sendCandidatesEvent).payload.jobId,
          candidateIds: (event as sendCandidatesEvent).payload.candidateIds,
          emails: (event as sendCandidatesEvent).payload.emails,
          emailBody: (event as sendCandidatesEvent).payload.emailBody,
        }),
        onDone: {
          target: State.EMAIL_SENT,
          actions: assign({
            isLoading: false,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_CANDIDATES]: {
      invoke: {
        src: 'fetchCandidates',
        input: ({ event }) => ({
          jobId: (event as fetchCandidatesEvent).payload.jobId,
          pageNumber: (event as fetchCandidatesEvent).payload.pageNumber,
          pageSize: (event as fetchCandidatesEvent).payload.pageSize,
          fitScore: (event as fetchCandidatesEvent).payload.fitScore,
          statuses: (event as fetchCandidatesEvent).payload.statuses,
          hasMandatorySkills: (event as fetchCandidatesEvent).payload
            .hasMandatorySkills,
          hasMandatoryTitleMatch: (event as fetchCandidatesEvent).payload
            .hasMandatoryTitleMatch,
          maxDistance: (event as fetchCandidatesEvent).payload.maxDistance,
          candidatesWithNoLocation: (event as fetchCandidatesEvent).payload
            .candidatesWithNoLocation,
          isApplicant: (event as fetchCandidatesEvent).payload.isApplicant,
          sort: (event as fetchCandidatesEvent).payload.sort,
          order: (event as fetchCandidatesEvent).payload.order,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            candidates: ({ event }) => event.output.data.candidates,
            totalCandidatePages: ({ event }) => event.output.data.totalPages,
            totalCandidateElements: ({ event }) =>
              event.output.data.totalElements,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_JOB_LIST]: {
      invoke: {
        src: 'fetchJobList',
        input: ({ event }) => ({
          pageNumber: (event as fetchJobListEvent).payload.pageNumber,
          pageSize: (event as fetchJobListEvent).payload.pageSize,
          sort: (event as fetchJobListEvent).payload.sort,
          order: (event as fetchJobListEvent).payload.order,
          status: (event as fetchJobListEvent).payload.status,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            jobList: ({ event }) => event.output.data.jobs,
            totalJobPages: ({ event }) => event.output.data.totalPages,
            totalJobElements: ({ event }) => event.output.data.totalElements,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.UPDATE_JOB]: {
      invoke: {
        src: 'updateJob',
        input: ({ event }) => ({
          id: (event as updateJobEvent).payload.id,
          job: (event as updateJobEvent).payload.job,
        }),
        onDone: {
          target: State.JOB_UPDATED,
          actions: assign({
            isLoading: false,
            jobDetails: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.CREATE_JOB]: {
      invoke: {
        src: 'createJob',
        input: ({ event }) => ({
          job: (event as createJobEvent).payload.job,
        }),
        onDone: {
          target: State.JOB_ADDED,
          actions: assign({
            isLoading: false,
            jobList: ({ event, context }) => [
              event.output.data,
              ...context.jobList,
            ],
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.FETCH_JOB_BY_ID]: {
      invoke: {
        src: 'fetchJobById',
        input: ({ event }) => ({
          id: (event as fetchJobByIdEvent).payload.id,
        }),
        onDone: {
          target: State.INITIAL,
          actions: assign({
            isLoading: false,
            jobDetails: ({ event }) => event.output.data,
          }),
        },
        onError: {
          target: State.ERROR,
          actions: assign({
            isLoading: false,
            error: ({ event }) => event.error as AxiosError,
          }),
        },
      },
    },
    [State.JOB_ADDED]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.JOB_UPDATED]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.EMAIL_SENT]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.CANDIDATE_SHARED]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.CANDIDATE_MACHED]: {
      after: {
        200: {
          target: State.INITIAL,
        },
      },
    },
    [State.ERROR]: {},
  },
});

import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Form, Button, Typography, Input } from 'antd';

import TagMaker from '../../../../components/common/tag-maker';

import styles from './index.module.css';

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  candidateIds: React.Key[];
  onClose: (emptySelectedRows?: boolean) => void;
  onSendCandidates: (
    jobId: number,
    candidateIds: React.Key[],
    emails: string[],
    emailBody: string,
  ) => void;
}

const ShareModal = ({
  isOpen,
  isLoading,
  candidateIds,
  onClose,
  onSendCandidates,
}: Props) => {
  const [form] = Form.useForm();
  const { jobId } = useParams();

  const handleEmailsChange = useCallback(
    (value: string[]) => {
      form.setFieldsValue({
        emails: value,
      });
    },
    [form],
  );

  const handleSave = useCallback(async () => {
    try {
      await form.validateFields();

      jobId &&
        onSendCandidates(
          parseInt(jobId),
          candidateIds,
          form.getFieldValue('emails'),
          form.getFieldValue('emailBody'),
        );

      form.resetFields();
      onClose(true);
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    }
  }, [jobId, candidateIds, form, onSendCandidates, onClose]);

  return (
    <Modal
      title="Share candidates list"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Send
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <div className={styles.content}>
          <Text>
            Send a short list of candidates to a colleague or hiring manager.
          </Text>
          <Form.Item
            name="emails"
            label="Recipient emails"
            rules={[{ required: true }]}
            style={{ paddingTop: 20 }}
          >
            <TagMaker
              label={'Add emails'}
              limit={5}
              onChange={handleEmailsChange}
            />
          </Form.Item>
          <Form.Item
            name="emailBody"
            label="Add your feedback"
            rules={[{ required: true }]}
          >
            <TextArea size="large" rows={4} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(ShareModal);

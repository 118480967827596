import { memo, useCallback } from 'react';

import { Button, Form, Select, Space } from 'antd';

import { Step } from '../../types/index.types';

const { Option } = Select;

interface Props {
  isLoading: boolean;
  onStepChange?: (step: Step) => void;
  onSubmitForm?: () => void;
}

const InvitationSettingsStep = ({
  isLoading,
  onStepChange,
  onSubmitForm,
}: Props) => {
  const handlePrev = useCallback(() => {
    onStepChange && onStepChange(Step.SkillsEducation);
  }, [onStepChange]);

  return (
    <>
      <Form.Item name="fitScore" label="Fitscore from">
        <Select size="large">
          <Option value="50">50</Option>
          <Option value="55">55</Option>
          <Option value="60">60</Option>
          <Option value="65">65</Option>
          <Option value="70">70</Option>
          <Option value="75">75</Option>
          <Option value="80">80</Option>
          <Option value="85">85</Option>
          <Option value="90">90</Option>
          <Option value="95">95</Option>
          <Option value="100">100</Option>
        </Select>
      </Form.Item>
      <Form.Item name="updatedDays" label="Updated">
        <Select size="large">
          <Option value="0">0 days</Option>
          <Option value="30">30 days</Option>
          <Option value="60">60 days</Option>
          <Option value="90">90 days</Option>
          <Option value="120">120 days</Option>
          <Option value="150">150 days</Option>
          <Option value="180">180 days</Option>
          <Option value="210">210 days</Option>
          <Option value="240">240 days</Option>
          <Option value="270">270 days</Option>
          <Option value="300">300 days</Option>
          <Option value="330">330 days</Option>
          <Option value="360">360 days</Option>
        </Select>
      </Form.Item>
      <Form.Item name="distance" label="Max distance">
        <Select size="large">
          <Option value="0">0 miles</Option>
          <Option value="10">10 miles</Option>
          <Option value="20">20 miles</Option>
          <Option value="30">30 miles</Option>
          <Option value="40">40 miles</Option>
          <Option value="50">50 miles</Option>
        </Select>
      </Form.Item>
      {onStepChange && (
        <Form.Item>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              type="primary"
              loading={isLoading}
              size="large"
              block
              shape="round"
              onClick={onSubmitForm}
            >
              Post job
            </Button>
            <Button
              size="large"
              type="text"
              block
              shape="round"
              onClick={handlePrev}
            >
              Previous
            </Button>
          </Space>
        </Form.Item>
      )}
    </>
  );
};

export default memo(InvitationSettingsStep);

import { AxiosError } from 'axios';

type NavigateFunction = (
  to: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  options?: { replace?: boolean; state?: any },
) => void;

export interface Context {
  token: string | null;
  isLoading: boolean;
  navigate?: NavigateFunction;
  error?: AxiosError;
}

export enum State {
  INITIAL = 'idle',
  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATED = 'AUTHENTICATED',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD = 'RESET_PASSWORD',
  EMAIL_SENT = 'EMAIL_SENT',
  PASSWORD_RESETED = 'PASSWORD_RESETED',
  ERROR = 'error',
}

export enum EventType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export type loginEvent = {
  type: EventType.LOGIN;
  payload: {
    email: string;
    password: string;
    navigate: NavigateFunction;
  };
};

export type logoutEvent = {
  type: EventType.LOGOUT;
};

export type resetPasswordRequestEvent = {
  type: EventType.RESET_PASSWORD_REQUEST;
  payload: {
    email: string;
  };
};

export type resetPasswordEvent = {
  type: EventType.RESET_PASSWORD;
  payload: {
    password: string;
    token: string;
    navigate: NavigateFunction;
  };
};

export type Event =
  | loginEvent
  | logoutEvent
  | resetPasswordRequestEvent
  | resetPasswordEvent;

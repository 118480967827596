import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { UserRole } from '../utils/role';

export const useAccount = () => {
  const [user, setUser] = useState<{
    userId: number;
    email: string;
    role?: UserRole;
  }>();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const decoded =
      token &&
      jwtDecode<{ userId: number; email: string; role: UserRole }>(token);
    const { userId, email, role } = decoded || {
      userId: 0,
      email: '',
      role: undefined,
    };
    setUser({ userId, email, role });
  }, []);

  return {
    account: user,
  };
};

import { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  Button,
  Row,
  Avatar,
  Space,
  Typography,
  Divider,
  Timeline,
  Badge,
  List,
  Flex,
  Tag,
  Spin,
  Popconfirm,
  Modal,
  Dropdown,
} from 'antd';
import {
  ArrowLeftOutlined,
  AuditOutlined,
  CheckCircleFilled,
  CommentOutlined,
  MailOutlined,
  InfoCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import { usePermissions } from '../../../../context/permission.context';

import Layout from '../../../../components/layout';
import Breadcrumb from '../../../../components/common/breadcrumb';

import styles from './index.module.css';
import {
  Candidate as CandidateType,
  HiringManager,
  CandidateFeedback,
  Status,
} from '../../../../fsm/jobs';
import AddFeedbackModal from '../../components/candidate/add-feedback-modal';
import ViewFeedbackModal from '../../components/candidate/view-feedback-modal';
import ShareProfileModal from '../../components/candidate/share-profile-modal';
import RioHistoryModal from '../../components/candidate/rio-history-modal';

import { User } from '../../../../fsm/users';

const { Text } = Typography;

const grayAI = `${process.env.PUBLIC_URL}/images/gray-ai.svg`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const STATUSES: any[] = [
  {
    color: 'gray',
    children: 'Reviewed',
    status: [Status.REVIEWED],
  },
  {
    color: 'gray',
    children: 'Email sent',
    status: [Status.INVITATION_SENT],
  },
  {
    color: 'gray',
    children: 'Rio screenings',
    status: [Status.RIO_SCREENING],
  },
  {
    color: 'gray',
    children: 'Alt interview',
    status: [Status.ALT_INTERVIEW],
  },
  {
    color: 'gray',
    children: 'HM review',
    status: [Status.HM_REVIEW],
  },
  {
    color: 'gray',
    children: 'Rejected - Not interested - Hired',
    status: [Status.REJECTED, Status.NOT_INTERESTED, Status.HIRED],
  },
];

interface Props {
  user?: User;
  isLoading: boolean;
  candidate?: CandidateType;
  candidateFeedback: CandidateFeedback | null;
  hiringManagers: HiringManager[];
  downloadUrl: string;
  onGetFeedback: (jobId: number, candidateId: number) => void;
  onAddFeedback: (jobId: number, candidateId: number, text: string) => void;
  onGetCandidate: (jobId: number, candidateId: number) => void;
  onFetchHiringManagers: (jobId: number) => void;
  onDownloadCandidateCV: (jobId: number, candidateId: number) => void;
  onShareCandidate: (
    jobId: number,
    candidateId: number,
    userId: number,
  ) => void;
  onUpdateCandidateStatus: (
    jobId: number,
    candidateId: number,
    status: string,
  ) => void;
}

const Candidate = ({
  user,
  isLoading,
  candidate,
  candidateFeedback,
  hiringManagers,
  downloadUrl,
  onGetCandidate,
  onAddFeedback,
  onGetFeedback,
  onFetchHiringManagers,
  onUpdateCandidateStatus,
  onDownloadCandidateCV,
  onShareCandidate,
}: Props) => {
  const [isAddFeedbackModalOpen, setIsAddFeedbackModalOpen] =
    useState<boolean>(false);
  const [isViewFeedbackModalOpen, setIsViewFeedbackModalOpen] =
    useState<boolean>(false);
  const [isShareProfileModalOpen, setIsShareProfileModalOpen] =
    useState<boolean>(false);
  const [isRioHistoryModalOpen, setIsRioHistoryModalOpen] =
    useState<boolean>(false);
  const { jobId, candidateId } = useParams();
  const { confirm } = Modal;
  const {
    canSeeCandidateRejectButton,
    canSeeCandidateViewHMFeedbackButton,
    canSeeCandidateAddFeedbackButton,
    canSeeCandidateNextStageButton,
  } = usePermissions();

  useEffect(() => {
    if (downloadUrl) {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'resume.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (jobId && candidateId) {
      onGetCandidate(parseInt(jobId), parseInt(candidateId));
    }
  }, [jobId, candidateId]);

  const confirmReject = useCallback(() => {
    if (jobId && candidateId) {
      onUpdateCandidateStatus(
        parseInt(jobId),
        parseInt(candidateId),
        Status.REJECTED,
      );
    }
  }, [jobId, candidateId, onUpdateCandidateStatus]);

  const confirmNextStage = useCallback(() => {
    if (jobId && candidateId) {
      let status;
      if (candidate?.status === Status.HM_REVIEW) {
        status = Status.HIRED;
      } else if (candidate?.status === Status.REVIEWED) {
        status = Status.INVITATION_SENT;
      }
      status &&
        onUpdateCandidateStatus(parseInt(jobId), parseInt(candidateId), status);
    }
  }, [jobId, candidateId, candidate?.status, onUpdateCandidateStatus]);

  const handleDownloadCandidateCV = useCallback(() => {
    if (jobId && candidateId) {
      onDownloadCandidateCV(parseInt(jobId), parseInt(candidateId));
    }
  }, [jobId, candidateId, onDownloadCandidateCV]);

  const handleAddFeedbackModalOpen = useCallback(() => {
    setIsAddFeedbackModalOpen(true);
  }, [setIsAddFeedbackModalOpen]);

  const handleAddFeedbackModalClose = useCallback(() => {
    setIsAddFeedbackModalOpen(false);
  }, [setIsAddFeedbackModalOpen]);

  const handleViewFeedbackModalOpen = useCallback(() => {
    setIsViewFeedbackModalOpen(true);
  }, [setIsViewFeedbackModalOpen]);

  const handleViewFeedbackModalClose = useCallback(() => {
    setIsViewFeedbackModalOpen(false);
  }, [setIsViewFeedbackModalOpen]);

  const handleShareProfileModalOpen = useCallback(() => {
    setIsShareProfileModalOpen(true);
  }, [setIsShareProfileModalOpen]);

  const handleShareProfileModalClose = useCallback(() => {
    setIsShareProfileModalOpen(false);
  }, [setIsShareProfileModalOpen]);

  const handleRioHistoryModalOpen = useCallback(() => {
    setIsRioHistoryModalOpen(true);
  }, [setIsRioHistoryModalOpen]);

  const handleRioHistoryModalClose = useCallback(() => {
    setIsRioHistoryModalOpen(false);
  }, [setIsRioHistoryModalOpen]);

  const handleSendreminderConfirmModalOpen = useCallback(() => {
    confirm({
      title: 'Send invitation reminder',
      icon: <ExclamationCircleFilled />,
      content: (
        <Space direction="vertical">
          <Typography>
            You sent an interview invite to Rebecca Duyon on Wednesday, Nov 14,
            2022 at 8:32 PM.
          </Typography>
          <Typography>Are you sure you want to send a reminder?</Typography>
        </Space>
      ),
      okText: 'Send reminder',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }, [confirm]);

  const disableNextStageButton = useMemo(() => {
    return (
      candidate?.status === Status.ALT_INTERVIEW ||
      candidate?.status === Status.RIO_SCREENING ||
      candidate?.status === Status.INVITATION_SENT ||
      candidate?.status === Status.REJECTED ||
      candidate?.status === Status.NOT_INTERESTED ||
      candidate?.status === Status.HIRED
    );
  }, [candidate?.status]);

  const disableViewFeedbackButton = useMemo(() => {
    return !(
      candidate?.status === Status.HM_REVIEW ||
      candidate?.status === Status.REJECTED ||
      candidate?.status === Status.NOT_INTERESTED ||
      candidate?.status === Status.HIRED
    );
  }, [candidate?.status]);

  const nextBtnlabel = useMemo(() => {
    if (candidate?.status === Status.HM_REVIEW) {
      return 'Hire';
    } else if (candidate?.status === Status.REVIEWED) {
      return 'Send email';
    } else {
      return 'Advance next stage';
    }
  }, [candidate?.status]);

  const nextStagePopconfirmData = useMemo(() => {
    if (candidate?.status === Status.HM_REVIEW) {
      return {
        title: 'Hire candidate',
        description: 'Are you sure you want to Hire the candidate?',
      };
    } else if (candidate?.status === Status.REVIEWED) {
      return {
        title: 'Send email',
        description: 'Are you sure you want to send email to the candidate?',
      };
    } else {
      return {
        title: '',
        description: '',
      };
    }
  }, [candidate?.status]);

  const statuses = useMemo(() => {
    if (candidate?.status === Status.NEW) {
      return STATUSES;
    } else if (
      candidate?.status === Status.REJECTED ||
      candidate?.status === Status.NOT_INTERESTED
    ) {
      return STATUSES.map((item) => {
        if (item.status.includes(Status.REJECTED)) {
          return { ...item, color: 'red', children: 'Rejected' };
        } else if (item.status.includes(Status.NOT_INTERESTED)) {
          return { ...item, color: '#FFD016', children: 'Not interested' };
        } else {
          return item;
        }
      });
    } else {
      if (candidate?.status) {
        const status = STATUSES.find((item) =>
          item.status.includes(candidate.status as Status),
        );
        const statusIndex = status && STATUSES.indexOf(status);

        return statusIndex >= 0
          ? STATUSES.map((item, index) => {
              if (index <= statusIndex) {
                if (item.status.includes(Status.HIRED)) {
                  return { ...item, color: 'green', children: 'Hired' };
                } else {
                  return { ...item, color: 'green' };
                }
              } else {
                return item;
              }
            })
          : [];
      } else {
        return [];
      }
    }
  }, [candidate?.status]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onMenuClick = (e: any) => {
    confirm({
      title: 'Confirmation',
      icon: <ExclamationCircleFilled />,
      content: (
        <Typography>
          {e.key === Status.HIRED &&
            'Are you sure you want to hire this candidate?'}
          {e.key === Status.NOT_INTERESTED &&
            'Are you sure that this candidate is not interested?'}
        </Typography>
      ),
      okText: 'Yes',
      onOk() {
        if (jobId && candidateId) {
          onUpdateCandidateStatus(
            parseInt(jobId),
            parseInt(candidateId),
            e.key,
          );
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Layout user={user}>
      <Breadcrumb
        renderLeft={
          <Button type="text" icon={<ArrowLeftOutlined />}>
            <Link to={`/jobs/${jobId}/candidates`}>Back to candidate list</Link>
          </Button>
        }
      />
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: 400 }}>
          <Spin tip="Loading..." />
        </Flex>
      ) : (
        <>
          <div className={styles.infoWrapper}>
            <div className={styles.infoCard}>
              <Row
                align={'middle'}
                justify={'space-between'}
                style={{ width: '100%' }}
              >
                <Space size={24}>
                  <Avatar size={isMobile ? 40 : 100}>
                    {candidate?.name
                      .split(' ')
                      .map((item) => item.charAt(0))
                      .join('')}
                  </Avatar>
                  <Space>
                    <Text strong style={{ fontSize: 32 }}>
                      {candidate?.fitScore}
                    </Text>
                    <Text style={{ fontSize: 20 }}>FIT SCORE</Text>
                    <img src={grayAI} />
                  </Space>
                </Space>
                <Space>
                  <Button
                    type="text"
                    shape="round"
                    onClick={handleDownloadCandidateCV}
                  >
                    View resume
                  </Button>
                  <Button shape="round" onClick={handleShareProfileModalOpen}>
                    Share profile
                  </Button>
                </Space>
              </Row>
              <Divider />
              <div className={styles.timelineWrapper}>
                <div>
                  <div className={styles.nameWrapper}>
                    <Text
                      strong={!isMobile}
                      style={{ fontSize: isMobile ? 14 : 20 }}
                    >
                      {candidate?.name}
                    </Text>
                    {!isMobile && <Divider type="vertical" />}
                    <Text
                      strong={!isMobile}
                      style={{ fontSize: isMobile ? 14 : 20 }}
                    >
                      {candidate?.jobTitle}
                    </Text>
                  </div>
                  <Row>
                    <Text type="secondary">Email: {candidate?.email}</Text>
                  </Row>
                  <Row>
                    <Text type="secondary">
                      Phone: {candidate?.phoneNumber}
                    </Text>
                  </Row>
                </div>
                <div>
                  <Text strong style={{ fontSize: 20 }}>
                    Candidate status
                  </Text>
                  {statuses && (
                    <Timeline style={{ marginTop: 20 }} items={statuses} />
                  )}
                </div>
              </div>
              <div className={styles.dateWrapper}>
                <Space
                  size={20}
                  direction={isMobile ? 'vertical' : 'horizontal'}
                >
                  {candidate?.lastActivityAt && (
                    <Text>
                      Last active:{' '}
                      {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      }).format(new Date(candidate.lastActivityAt * 1000))}
                    </Text>
                  )}
                  <Space>
                    <Badge status="success" />
                    <Text>
                      Updated: 
                      {candidate?.updatedAt &&
                        new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(candidate.updatedAt * 1000))}
                    </Text>
                  </Space>
                </Space>
                <Space>
                  {canSeeCandidateRejectButton && (
                    <Popconfirm
                      title="Reject candidate"
                      description="Are you sure you want to reject the candidate?"
                      onConfirm={confirmReject}
                      icon={<InfoCircleFilled style={{ color: 'red' }} />}
                      okText="Reject"
                      cancelText="No"
                    >
                      <Button
                        type="text"
                        shape="round"
                        danger
                        size={isMobile ? 'small' : 'large'}
                      >
                        Reject
                      </Button>
                    </Popconfirm>
                  )}
                  {canSeeCandidateViewHMFeedbackButton && (
                    <Button
                      shape="round"
                      size={isMobile ? 'small' : 'large'}
                      disabled={disableViewFeedbackButton}
                      onClick={handleViewFeedbackModalOpen}
                    >
                      View HM feedback
                    </Button>
                  )}
                  {canSeeCandidateNextStageButton && (
                    <>
                      {candidate?.status === Status.HM_REVIEW ? (
                        <Dropdown.Button
                          type="primary"
                          disabled={disableNextStageButton}
                          size={isMobile ? 'small' : 'large'}
                          menu={{
                            items: [
                              {
                                key: Status.HIRED,
                                label: 'Hire',
                              },
                              {
                                key: Status.NOT_INTERESTED,
                                label: 'Not interested',
                              },
                            ],
                            onClick: onMenuClick,
                          }}
                        >
                          Next stage
                        </Dropdown.Button>
                      ) : (
                        <Popconfirm
                          title={nextStagePopconfirmData.title}
                          description={nextStagePopconfirmData.description}
                          onConfirm={confirmNextStage}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="primary"
                            shape="round"
                            size={isMobile ? 'small' : 'large'}
                            disabled={disableNextStageButton}
                          >
                            {nextBtnlabel}
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  )}
                  {canSeeCandidateAddFeedbackButton && (
                    <Button
                      type="primary"
                      shape="round"
                      size={isMobile ? 'small' : 'large'}
                      onClick={handleAddFeedbackModalOpen}
                    >
                      Add feedback
                    </Button>
                  )}
                </Space>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.content} style={{ marginBottom: 24 }}>
              <Space style={{ fontSize: 20, marginBottom: 20 }}>
                <AuditOutlined />
                <Text strong style={{ fontSize: 20 }}>
                  Candidate profile summary
                </Text>
              </Space>
              <Divider />
              <Space>
                <Text strong style={{ fontSize: 20 }}>
                  Experience
                </Text>
                <img src={grayAI} />
              </Space>
              <Row style={{ marginBottom: 24 }}>
                <Text type="secondary">
                  Previous job roles, helping to evaluate their suitability for
                  the position.
                </Text>
              </Row>
              <List
                size="large"
                style={{ marginBottom: 48 }}
                header={<Text strong>Professional Experience</Text>}
                bordered
                dataSource={candidate?.experiences}
                renderItem={(item) => (
                  <List.Item>
                    {item.jobTitle}{' '}
                    <Text type="secondary">
                      {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'short',
                      }).format(new Date(item.startDate * 1000))}
                      {' - '}
                      {new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'short',
                      }).format(new Date(item.endDate * 1000))}
                    </Text>
                  </List.Item>
                )}
              />
              <Row>
                <Text strong style={{ fontSize: 20 }}>
                  Education
                </Text>
              </Row>
              <Row style={{ marginBottom: 24 }}>
                <Text type="secondary">
                  Academic qualifications and degrees, providing insight into
                  their educational background.
                </Text>
              </Row>
              <List
                size="large"
                bordered
                style={{ marginBottom: 48 }}
                dataSource={candidate?.degrees}
                renderItem={(item) => (
                  <List.Item>
                    {item.academicDegree ? `${item.academicDegree} of ` : ''}
                    {item.major}
                  </List.Item>
                )}
              />
              <Space>
                <Text strong style={{ fontSize: 20 }}>
                  Required skills
                </Text>
                <img src={grayAI} />
              </Space>
              <Row style={{ marginBottom: 24 }}>
                <Text type="secondary">
                  AI-detected skills, some approved by Rio, required for the
                  role.
                </Text>
              </Row>
              <Flex gap="4px 0" wrap style={{ marginBottom: 48 }}>
                {candidate?.requiredSkills.length
                  ? candidate?.requiredSkills.map((item, index) => (
                      <Tag
                        key={index}
                        icon={
                          <CheckCircleFilled style={{ color: '#52C41A' }} />
                        }
                      >
                        {item}
                      </Tag>
                    ))
                  : '---'}
              </Flex>
              <Space>
                <Text strong style={{ fontSize: 20 }}>
                  Preferred skills
                </Text>
                <img src={grayAI} />
              </Space>
              <Row style={{ marginBottom: 24 }}>
                <Text type="secondary">
                  AI-detected skills, some approved by Rio, preferred for the
                  role.
                </Text>
              </Row>
              <Flex gap="4px 0" wrap>
                {candidate?.preferredSkills.length
                  ? candidate?.preferredSkills.map((item, index) => (
                      <Tag
                        key={index}
                        icon={
                          <CheckCircleFilled style={{ color: '#52C41A' }} />
                        }
                      >
                        {item}
                      </Tag>
                    ))
                  : '---'}
              </Flex>
            </div>
            <div className={styles.content} style={{ marginBottom: 24 }}>
              <Space style={{ fontSize: 20, marginBottom: 20 }}>
                <CommentOutlined />
                <Text strong style={{ fontSize: 20 }}>
                  Communication
                </Text>
              </Space>
              <Divider />
              <Row align={'middle'} justify={'space-between'}>
                <Text strong style={{ fontSize: 20 }}>
                  Conversation history
                </Text>
                <Space>
                  <Button shape="round" size={isMobile ? 'small' : 'middle'}>
                    Share Rio link with candidate
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    size={isMobile ? 'small' : 'middle'}
                    onClick={handleRioHistoryModalOpen}
                  >
                    View conversation
                  </Button>
                </Space>
              </Row>
              <Row style={{ marginBottom: 24 }}>
                <Text type="secondary">
                  Manage and track all emails sent to the candidate.
                </Text>
              </Row>
              <Row align={'top'} justify={'space-between'}>
                <Row
                  style={{
                    fontSize: 24,
                    marginBottom: 48,
                    gap: 20,
                  }}
                  align={'top'}
                  justify={'start'}
                >
                  <MailOutlined />
                  <Space direction="vertical" size={0}>
                    <Row align={'middle'}>
                      <Text strong>Invitation email</Text>
                      <Divider type="vertical" />
                      <Space>
                        <Badge status="success" />
                        <Text>
                          This candidate has not accepted the invite. Send a
                          reminder
                        </Text>
                      </Space>
                    </Row>
                    <Text type="secondary">
                      {candidate &&
                        new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(candidate.statusChangedAt * 1000))}
                    </Text>
                  </Space>
                </Row>
                {candidate?.status === Status.INVITATION_SENT && (
                  <Button
                    type="text"
                    shape="round"
                    size={isMobile ? 'small' : 'middle'}
                    onClick={handleSendreminderConfirmModalOpen}
                  >
                    Send reminder
                  </Button>
                )}
              </Row>
            </div>
          </div>
        </>
      )}
      <AddFeedbackModal
        isOpen={isAddFeedbackModalOpen}
        isLoading={isLoading}
        onClose={handleAddFeedbackModalClose}
        onSubmit={onAddFeedback}
      />
      <ViewFeedbackModal
        isOpen={isViewFeedbackModalOpen}
        isLoading={isLoading}
        feedback={candidateFeedback}
        onClose={handleViewFeedbackModalClose}
        onGetFeedback={onGetFeedback}
      />
      <ShareProfileModal
        isOpen={isShareProfileModalOpen}
        isLoading={isLoading}
        name={candidate?.name}
        hiringManagers={hiringManagers}
        onClose={handleShareProfileModalClose}
        onFetchHiringManagers={onFetchHiringManagers}
        onShareCandidate={onShareCandidate}
      />
      <RioHistoryModal
        data={candidate?.rioMessages}
        name={candidate?.name}
        isOpen={isRioHistoryModalOpen}
        isLoading={isLoading}
        onClose={handleRioHistoryModalClose}
      />
    </Layout>
  );
};

export default memo(Candidate);

import { useCallback, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAccount } from '../../context/account.context';
import { EventType, State, User } from '../../fsm/users';

import { message } from 'antd';

import Calendar from './pages/calendar';

const CalendarRoutes = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { state, send } = useAccount();

  useEffect(() => {
    if (state.value.match(State.USER_UPDATED)) {
      messageApi.open({
        type: 'success',
        content: 'Calendar link has been successfully added.',
      });
    }
  }, [state.value]);

  const handleUpdateUser = useCallback(
    (data: Partial<User>, id: number, file?: File) => {
      send({
        type: EventType.UPDATE_USER,
        payload: { data, id, file },
      });
    },
    [send],
  );

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Calendar
              user={state.context.user}
              isLoading={state.context.isLoading}
              onUpdateUser={handleUpdateUser}
            />
          }
        />
      </Routes>
      {contextHolder}
    </>
  );
};

export default CalendarRoutes;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Jobs from '../../smallApps/jobs';
import Auth from '../../smallApps/auth';
import Users from '../../smallApps/users';
import NotFound from '../../smallApps/not-found';
import CandidatesShare from '../../smallApps/share-candidates';
import Calendar from '../../smallApps/calendar';

const wrapPrivateRoute = (element: JSX.Element) => {
  return <PrivateRoute>{element}</PrivateRoute>;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/jobs" replace />} />
        <Route
          path="/shared-candidates/*"
          element={wrapPrivateRoute(<CandidatesShare />)}
        />
        <Route path="/jobs/*" element={wrapPrivateRoute(<Jobs />)} />
        <Route path="/users/*" element={wrapPrivateRoute(<Users />)} />
        <Route path="/calendar/*" element={wrapPrivateRoute(<Calendar />)} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;

import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  ProductOutlined,
  UserOutlined,
  SolutionOutlined,
  MenuOutlined,
  DownOutlined,
  ArrowRightOutlined,
  ExceptionOutlined,
  ReadOutlined,
  CloseOutlined,
  SafetyCertificateOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { Avatar, Divider, type MenuProps } from 'antd';
import { Layout, Menu, Button, Row, Drawer, Dropdown, Space } from 'antd';

import { usePermissions } from '../../context/permission.context';
import { User } from '../../fsm/users';

import styles from './index.module.css';

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
  user?: User;
  children: React.ReactNode;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const logo = `${process.env.PUBLIC_URL}/images/logo.svg`;

const AppLayout = ({ children, user }: Props) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    canSeeUserManagmentNavigationButton,
    canSeeCandidatesAssessmentNavigationButton,
  } = usePermissions();
  const defaultOpenKeys = useMemo(() => {
    return location.pathname
      .split('/')
      .slice(0, 2)
      .map((_, idx, arr) => arr.slice(0, idx + 1).join('/'));
  }, [location]);

  const items: MenuItem[] = [
    ...(isMobile
      ? [
          getItem('My profile', '/users/my-account', <UserOutlined />),
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          { type: 'divider' } as any,
        ]
      : []),
    ...(canSeeCandidatesAssessmentNavigationButton
      ? [
          getItem(
            'Candidates assessment',
            '/shared-candidates',
            <SolutionOutlined />,
          ),
        ]
      : []),
    getItem('Job list', '/jobs', <ProductOutlined />),
    ...(canSeeUserManagmentNavigationButton
      ? [getItem('User managment', '/users', <UserOutlined />)]
      : []),
    getItem('Calendar settings', '/calendar', <CalendarOutlined />),
  ];

  const footerItems: MenuItem[] = [
    getItem(
      'Privacy policy',
      'https://www.google.com/',
      <SafetyCertificateOutlined />,
    ),
    getItem('Terms of use', 'https://www.google.com/', <ReadOutlined />),
    getItem(
      'Contact help desk',
      'https://www.google.com/',
      <ExceptionOutlined />,
    ),
  ];

  const handleClick = useCallback(
    (e: { key: string }) => {
      navigate(e.key);
    },
    [navigate],
  );

  const handleFooterClick = useCallback((e: { key: string }) => {
    window.open(e.key, '_blank');
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/auth/login');
  }, []);

  const showDrawer = useCallback(() => {
    setVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Layout style={{ height: '100vh' }}>
      {!isMobile && (
        <Sider theme="light" width={280} className={styles.sider}>
          <div className={styles.logoWrapper}>
            <img src={logo} />
          </div>
          <Divider type="horizontal" style={{ margin: 0 }} />
          <div className={styles.navigation}>
            <Menu
              theme="light"
              defaultSelectedKeys={defaultOpenKeys}
              mode="inline"
              className={styles.menu}
              items={items}
              onClick={handleClick}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Menu
                theme="light"
                mode="inline"
                className={styles.menu}
                items={footerItems}
                onClick={handleFooterClick}
              />
            </Space>
          </div>
        </Sider>
      )}
      <Layout>
        <Header className={styles.header}>
          {!isMobile && (
            <Row justify="end" align="middle">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: <a href="/users/my-account">My profile</a>,
                    },
                    {
                      key: '2',
                      label: <div onClick={handleLogout}>Log out</div>,
                    },
                  ],
                }}
              >
                <Space>
                  <Avatar src={user?.profilePictureUrl}>
                    {`${user?.firstName} ${user?.lastName}`
                      .split(' ')
                      .map((item) => item.charAt(0))
                      .join('')}
                  </Avatar>
                  <DownOutlined />
                </Space>
              </Dropdown>
            </Row>
          )}
          {isMobile && (
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: 16 }}
            >
              <img src={logo} />
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={showDrawer}
              />
            </Row>
          )}
        </Header>
        <Content id="content" className={styles.content}>
          {children}
        </Content>
      </Layout>
      {isMobile && (
        <Drawer
          placement="right"
          closable={false}
          onClose={closeDrawer}
          open={visible}
        >
          <Row align={'middle'} justify={'end'}>
            <Button
              icon={<CloseOutlined />}
              type="text"
              onClick={closeDrawer}
            />
          </Row>
          <div className={styles.mobileMenu}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Menu
                theme="light"
                defaultSelectedKeys={defaultOpenKeys}
                mode="inline"
                items={items}
                style={{ borderInlineEnd: ' unset !important' }}
                onClick={handleClick}
              />
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Menu
                theme="light"
                mode="inline"
                items={footerItems}
                style={{ borderInlineEnd: ' unset !important' }}
                onClick={handleFooterClick}
              />
            </Space>
            <Space
              direction="vertical"
              style={{ width: '100%', height: 'max-width' }}
            >
              <Divider type="horizontal" style={{ margin: 0 }} />
              <Menu style={{ borderInlineEnd: ' unset !important' }}>
                <Menu.Item
                  icon={<ArrowRightOutlined />}
                  key="Log out"
                  onClick={handleLogout}
                >
                  Log out
                </Menu.Item>
              </Menu>
            </Space>
          </div>
        </Drawer>
      )}
    </Layout>
  );
};

export default memo(AppLayout);

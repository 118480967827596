import { memo, useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Space, Flex, Divider, Typography, Row } from 'antd';
import { MailOutlined, EditOutlined } from '@ant-design/icons';

import styles from './index.module.css';
import Modal from './Modal';
import { Job, JobPayload } from '../../../../fsm/jobs';

const { Title, Text } = Typography;

const grayAI = `${process.env.PUBLIC_URL}/images/gray-ai.svg`;

interface Props {
  data: Job;
  onUpdateDetails: (job: JobPayload) => void;
}

const InvitationSettingsEdit = ({ data, onUpdateDetails }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return (
    <div className={styles.body}>
      <Flex align="center" justify="space-between">
        <Space>
          <MailOutlined />
          <Title level={4} style={{ margin: 0 }}>
            Invitation settings
          </Title>
        </Space>
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={handleModalOpen}
        >
          {isMobile ? '' : 'Edit invitation settings'}
        </Button>
      </Flex>
      <Divider />
      <div className={styles.cardWrapper}>
        <div className={styles.card}>
          <Row justify={'center'}>
            <Space>
              <Text type="secondary">Fitscore from</Text>
              <img src={grayAI} />
            </Space>
          </Row>
          <Flex align="center" justify="center">
            <Title>
              {data.invitationSettings.fitScore
                ? data.invitationSettings.fitScore
                : 'N/A'}
            </Title>
          </Flex>
        </div>
        <div className={styles.card}>
          <Row justify={'center'}>
            <Text type="secondary">Updated days</Text>
          </Row>
          <Flex align="center" justify="center">
            <Title>
              {data.invitationSettings.updatedDays
                ? data.invitationSettings.updatedDays + 'd'
                : 'N/A'}
            </Title>
          </Flex>
        </div>
        <div className={styles.card}>
          <Row justify={'center'}>
            <Text type="secondary">Max distance</Text>
          </Row>
          <Flex align="center" justify="center">
            <Title>
              {data.invitationSettings.distance
                ? data.invitationSettings.distance + 'mi'
                : 'N/A'}
            </Title>
          </Flex>
        </div>
      </div>
      <Modal
        data={data}
        isOpen={isModalOpen}
        isLoading={false}
        onClose={handleModalClose}
        onSubmit={onUpdateDetails}
      />
    </div>
  );
};

export default memo(InvitationSettingsEdit);

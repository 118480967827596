import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Table, Button, Typography, Badge, Space, Divider, Row } from 'antd';
import type { TablePaginationConfig } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';
import { PlusOutlined } from '@ant-design/icons';

import Breadcrumb from '../../../../components/common/breadcrumb';

import { Job } from '../../../../fsm/jobs/jobs.fsm.types';
import Layout from '../../../../components/layout';
import styles from './index.module.css';

import { usePermissions } from '../../../../context/permission.context';
import { User } from '../../../../fsm/users';

const { Text } = Typography;

const orderMapping = {
  ascend: 'ASC',
  descend: 'DESC',
};

interface Props {
  user?: User;
  data: Array<Job>;
  isLoading: boolean;
  totalElements: number;
  totalPages: number;
  onFetchJobs: (
    pageNumber: number,
    pageSize: number,
    sort?: string,
    order?: string,
    status?: string,
  ) => void;
}

const breadcrumbItems = [
  {
    title: 'Dashboard',
  },
  {
    title: 'Job list',
  },
];

const JobList = ({
  user,
  data,
  isLoading,
  totalElements,
  totalPages,
  onFetchJobs,
}: Props) => {
  const [pageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(isMobile ? 6 : 10);
  const [sortOrder] = useState<string | undefined>('DESC');
  const [sortField] = useState<string | undefined>('id');
  const {
    canAddNewJob,
    canSeeJobRecruiterColumn,
    canSeeJobHiringManagerColumn,
    canSeeJobCustomerNameColumn,
    canSeeJobCompanyColumn,
  } = usePermissions();

  useEffect(() => {
    onFetchJobs(pageNumber, pageSize, sortField, sortOrder);
  }, []);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      filters: Record<string, any>,
      sorter: SorterResult<Job> | SorterResult<Job>[],
    ) => {
      const currentSorter = Array.isArray(sorter) ? sorter[0] : sorter;

      onFetchJobs(
        (pagination.current as number) || pageNumber,
        pageSize,
        (currentSorter.field as string) || sortField,
        orderMapping[currentSorter.order as keyof typeof orderMapping] ||
          sortOrder,
        filters?.status?.length ? filters?.status.join(',') : undefined,
      );
    },
    [pageSize, sortField, sortOrder, pageNumber, onFetchJobs],
  );

  const columns = [
    {
      title: 'Avrio ID',
      dataIndex: 'id',
      key: 'id',
      width: '8%',
      sorter: true,
    },
    {
      title: 'ATS ID',
      dataIndex: 'atsId',
      key: 'atsId',
      width: '8%',
    },
    ...(canSeeJobCompanyColumn
      ? [
          {
            title: 'Company',
            dataIndex: 'employerName',
            key: 'employerName',
            render: (employerName?: string) => (
              <Typography>{employerName || 'N/A'}</Typography>
            ),
          },
        ]
      : []),
    {
      title: 'Job title',
      dataIndex: 'title',
      key: 'title',
      width: '24%',
    },
    ...(canSeeJobCustomerNameColumn
      ? [
          {
            title: 'Customer name',
            dataIndex: 'customerName',
            key: 'customerName',
          },
        ]
      : []),
    ...(canSeeJobRecruiterColumn
      ? [
          {
            title: 'Recruiter',
            dataIndex: 'recruiterName',
            key: 'recruiter',
          },
        ]
      : []),
    ...(canSeeJobHiringManagerColumn
      ? [
          {
            title: 'Hiring manager',
            dataIndex: 'hiringManagerName',
            key: 'hiringManagerName',
          },
        ]
      : []),
    {
      title: 'Posted date',
      dataIndex: 'postedAt',
      key: 'postedAt',
      render: (postedAt?: number) => (
        <div className={styles.nowrap}>
          {postedAt
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(postedAt * 1000))
            : 'No date'}
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterMultiple: false,
      filters: [
        { text: 'Open', value: 'OPEN' },
        { text: 'Closed', value: 'CLOSED' },
      ],
      render: (status: string) => (
        <Space>
          {status === 'OPEN' && (
            <>
              <Badge status="success" /> {'Open'}
            </>
          )}
          {status === 'CLOSED' && (
            <>
              <Badge status="error" /> {'Closed'}
            </>
          )}
        </Space>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'listing',
      key: 'listing',
      render: (_a: unknown, { id }: Job) => (
        <Space size={20}>
          <Link className={styles.nowrap} to={id.toString()}>
            Job post
          </Link>
          <Link className={styles.nowrap} to={`${id.toString()}/candidates`}>
            Candidates
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Layout user={user}>
      <Breadcrumb
        items={breadcrumbItems}
        renderRight={
          canAddNewJob && (
            <Button type="primary" shape="round" icon={<PlusOutlined />}>
              <Link to="create">Add new job</Link>
            </Button>
          )
        }
      />
      <div className={styles.wrapper}>
        <Row align={'middle'} style={{ padding: 8 }}>
          <Text style={{ fontSize: 16 }}>Job list</Text>
          <Divider type="vertical" />
          <Text type="secondary" style={{ fontSize: 16 }}>
            Total {totalElements}
          </Text>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          scroll={{ x: 800 }}
          pagination={
            totalPages > 1 && {
              total: totalElements,
              pageSize: pageSize,
              showSizeChanger: false,
            }
          }
          onChange={handleTableChange}
        />
      </div>
    </Layout>
  );
};

export default memo(JobList);

import { memo, useCallback, useState } from 'react';
import {
  Drawer,
  Button,
  Space,
  Typography,
  Form,
  Select,
  Row,
  Checkbox,
  Divider,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  ClockCircleOutlined,
  CarryOutOutlined,
  MailOutlined,
  CommentOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Status } from '../../../../fsm/jobs';

const { Text } = Typography;
const { Option } = Select;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApply: (filters: {
    fitScore?: number;
    statuses?: Status[];
    hasMandatorySkills?: boolean;
    hasMandatoryTitleMatch?: boolean;
    maxDistance?: number;
    candidatesWithNoLocation?: boolean;
    isApplicant?: boolean;
  }) => void;
}

const Filters = ({ isOpen, onClose, onApply }: Props) => {
  const [form] = Form.useForm();
  const [allIsChecked, setAllIsChecked] = useState(false);

  const onStatusChange = (checkedValues: string[]) => {
    if (checkedValues.length) {
      setAllIsChecked(false);
    }
  };

  const handleApply = useCallback(() => {
    onApply({
      fitScore: form.getFieldValue('fitScore'),
      statuses: form.getFieldValue('statuses'),
      hasMandatorySkills: form.getFieldValue('hasMandatorySkills'),
      hasMandatoryTitleMatch: form.getFieldValue('hasMandatoryTitleMatch'),
      maxDistance: form.getFieldValue('maxDistance'),
      candidatesWithNoLocation: form.getFieldValue('candidatesWithNoLocation'),
      isApplicant: form.getFieldValue('isApplicant'),
    });
  }, [form, onApply]);

  const handleReset = useCallback(() => {
    form.resetFields();
    setAllIsChecked(false);
    handleApply();
  }, [form, setAllIsChecked, onApply]);

  const onAllStatuseChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue('statuses', [
        Status.NEW,
        Status.REVIEWED,
        Status.INVITATION_SENT,
        Status.RIO_SCREENING,
        Status.ALT_INTERVIEW,
      ]);
    } else {
      form.setFieldValue('statuses', []);
    }
    setAllIsChecked(e.target.checked);
  };

  return (
    <Drawer
      title="Filters"
      onClose={onClose}
      open={isOpen}
      extra={
        <Space>
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <Text strong style={{ fontSize: 16 }}>
          Fitscore and distance
        </Text>
        <Form.Item
          name="fitScore"
          label="Fitscore from"
          style={{ marginBottom: 8, marginTop: 20 }}
        >
          <Select size="large" placeholder="Select fitscore from">
            <Option value="50">50</Option>
            <Option value="55">55</Option>
            <Option value="60">60</Option>
            <Option value="65">65</Option>
            <Option value="70">70</Option>
            <Option value="75">75</Option>
            <Option value="80">80</Option>
            <Option value="85">85</Option>
            <Option value="90">90</Option>
            <Option value="95">95</Option>
            <Option value="100">100</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="hasMandatorySkills"
          valuePropName="checked"
          style={{ marginBottom: 8 }}
        >
          <Checkbox>Mandatory skills</Checkbox>
        </Form.Item>
        <Form.Item name="hasMandatoryTitleMatch" valuePropName="checked">
          <Checkbox>Mandatory title match</Checkbox>
        </Form.Item>
        <Form.Item
          name="maxDistance"
          label="Max distance"
          style={{ marginBottom: 8 }}
        >
          <Select size="large" placeholder="Select max distance">
            <Option value="0">0 miles</Option>
            <Option value="10">10 miles</Option>
            <Option value="20">20 miles</Option>
            <Option value="30">30 miles</Option>
            <Option value="40">40 miles</Option>
            <Option value="50">50 miles</Option>
          </Select>
        </Form.Item>
        <Form.Item name="candidatesWithNoLocation" valuePropName="checked">
          <Checkbox>Include candidates with missing addresses</Checkbox>
        </Form.Item>
        <Text strong style={{ fontSize: 16 }}>
          Applicants
        </Text>
        <Form.Item
          name="isApplicant"
          valuePropName="checked"
          style={{ marginTop: 20 }}
        >
          <Checkbox>Show only applicants</Checkbox>
        </Form.Item>
        <Text strong style={{ fontSize: 16 }}>
          Status
        </Text>
        <Form.Item name="all" style={{ marginTop: 20, marginBottom: 16 }}>
          <Checkbox checked={allIsChecked} onChange={onAllStatuseChange}>
            All
          </Checkbox>
        </Form.Item>
        <Form.Item name="statuses">
          <Checkbox.Group style={{ width: '100%' }} onChange={onStatusChange}>
            <Row justify="space-between">
              <Checkbox value={Status.NEW}>Unreviewed</Checkbox>
              <ClockCircleOutlined />
            </Row>
            <Divider style={{ margin: 12, marginLeft: 0 }} />
            <Row justify="space-between">
              <Checkbox value={Status.REVIEWED}>Reviewed</Checkbox>
              <CarryOutOutlined />
            </Row>
            <Divider style={{ margin: 12, marginLeft: 0 }} />
            <Row justify="space-between">
              <Checkbox value={Status.INVITATION_SENT}>Email sent</Checkbox>
              <MailOutlined />
            </Row>
            <Divider style={{ margin: 12, marginLeft: 0 }} />
            <Row justify="space-between">
              <Checkbox value={Status.RIO_SCREENING}>Rio screening</Checkbox>
              <CommentOutlined />
            </Row>
            <Divider style={{ margin: 12, marginLeft: 0 }} />
            <Row justify="space-between">
              <Checkbox value={Status.ALT_INTERVIEW}>Alt interview</Checkbox>
              <SolutionOutlined />
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default memo(Filters);

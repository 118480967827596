import { useCallback, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { jobsFSM, EventType, JobPayload, State, Status } from '../../fsm/jobs';
import { useAccount } from '../../context/account.context';

import { message } from 'antd';

import JobList from './pages/job-list';
import CreateJob from './pages/create-job';
import JobDetails from './pages/job-details';
import Candidates from './pages/candidates';
import Candidate from './pages/candidate';

const JobsRoutes = () => {
  const [state, send] = useMachine(jobsFSM);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { user } = useAccount();

  const handleFetchJobs = useCallback(
    (
      pageNumber: number,
      pageSize: number,
      sort?: string,
      order?: string,
      status?: string,
    ) => {
      send({
        type: EventType.FETCH_JOB_LIST,
        payload: { pageNumber, pageSize, sort, order, status },
      });
    },
    [send],
  );

  const handleCreateJob = useCallback(
    (job: JobPayload) => {
      send({ type: EventType.CREATE_JOB, payload: { job } });
    },
    [send],
  );

  const handleCandidateMatch = useCallback(
    (jobId: number) => {
      send({ type: EventType.START_CANDIDATE_MATCHING, payload: { jobId } });
    },
    [send],
  );

  const handleFetchDetails = useCallback(
    (id: number) => {
      send({ type: EventType.FETCH_JOB_BY_ID, payload: { id } });
    },
    [send],
  );

  const handleUpdateDetails = useCallback(
    (id: number, job: JobPayload) => {
      send({ type: EventType.UPDATE_JOB, payload: { id, job } });
    },
    [send],
  );

  const handleFetchCandidates = useCallback(
    (
      jobId: number,
      pageNumber: number,
      pageSize: number,
      fitScore?: number,
      statuses?: Status[],
      hasMandatorySkills?: boolean,
      hasMandatoryTitleMatch?: boolean,
      maxDistance?: number,
      candidatesWithNoLocation?: boolean,
      isApplicant?: boolean,
      sort?: string,
      order?: string,
    ) => {
      send({
        type: EventType.FETCH_CANDIDATES,
        payload: {
          jobId,
          pageNumber,
          pageSize,
          fitScore,
          statuses,
          hasMandatorySkills,
          hasMandatoryTitleMatch,
          maxDistance,
          candidatesWithNoLocation,
          isApplicant,
          sort,
          order,
        },
      });
    },
    [send],
  );

  const handleFetchJobHiringManagers = useCallback(
    (jobId: number) => {
      send({
        type: EventType.FETCH_JOB_HIRING_MANAGERS,
        payload: { jobId },
      });
    },
    [send],
  );

  const handleFetchHiringManagers = useCallback(() => {
    send({
      type: EventType.FETCH_HIRING_MANAGERS,
    });
  }, [send]);

  const handleAssignHiringManagers = useCallback(
    (jobId: number, hiringManagerId: number) => {
      send({
        type: EventType.ASSIGN_HIRING_MANAGER,
        payload: { jobId, hiringManagerId },
      });
    },
    [send],
  );

  const handleGetCandidate = useCallback(
    (jobId: number, candidateId: number) => {
      send({
        type: EventType.GET_CANDIDATE,
        payload: { jobId, candidateId },
      });
    },
    [send],
  );

  const handleSendCandidates = useCallback(
    (
      jobId: number,
      candidateIds: React.Key[],
      emails: string[],
      emailBody: string,
    ) => {
      send({
        type: EventType.SEND_CANDIDATES,
        payload: { jobId, candidateIds, emails, emailBody },
      });
    },
    [send],
  );

  const handleAddFeedback = useCallback(
    (jobId: number, candidateId: number, text: string) => {
      send({
        type: EventType.ADD_CANDIDATE_FEEDBACK,
        payload: { jobId, candidateId, text },
      });
    },
    [send],
  );

  const handleGetFeedback = useCallback(
    (jobId: number, candidateId: number) => {
      send({
        type: EventType.GET_CANDIDATE_FEEDBACK,
        payload: { jobId, candidateId },
      });
    },
    [send],
  );

  const handleUpdateCandidateStatus = useCallback(
    (jobId: number, candidateId: number, status: string) => {
      send({
        type: EventType.UPDATE_CANDIDATE_STATUS,
        payload: { status, jobId, candidateId },
      });
    },
    [send],
  );

  const handleDownloadCandidateCV = useCallback(
    (jobId: number, candidateId: number) => {
      send({
        type: EventType.GET_CANDIDATE_DOWNLOAD_CV_URL,
        payload: { jobId, candidateId },
      });
    },
    [send],
  );

  const handleShareCandidate = useCallback(
    (jobId: number, candidateId: number, userId: number) => {
      send({
        type: EventType.SHARE_CANDIDATE,
        payload: { jobId, candidateId, userId },
      });
    },
    [send],
  );

  useEffect(() => {
    if (state.value.match(State.EMAIL_SENT)) {
      messageApi.open({
        type: 'success',
        content: 'Email successfully sent.',
      });
    }
  }, [state.value]);

  useEffect(() => {
    if (state.value.match(State.JOB_ADDED)) {
      messageApi.open({
        type: 'success',
        content: 'Job has been successfully posted.',
      });
      navigate('/jobs');
    }
  }, [state.value]);

  useEffect(() => {
    if (state.value.match(State.JOB_UPDATED)) {
      messageApi.open({
        type: 'success',
        content: 'Job has been successfully updated.',
      });
    }
  }, [state.value]);

  useEffect(() => {
    if (state.value.match(State.SHARE_CANDIDATE)) {
      messageApi.open({
        type: 'success',
        content: 'Candidate profile shared successfully.',
      });
    }
  }, [state.value]);

  useEffect(() => {
    if (state.value.match(State.START_CANDIDATE_MATCHING)) {
      messageApi.open({
        type: 'loading',
        content:
          'Candidate matching process has started. Results will be ready in 10 minutes.',
      });
    }
  }, [state.value]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <JobList
              user={user}
              data={state.context.jobList}
              totalElements={state.context.totalJobElements}
              isLoading={state.context.isLoading}
              totalPages={state.context.totalJobPages}
              onFetchJobs={handleFetchJobs}
            />
          }
        />
        <Route
          path="/create"
          element={
            <CreateJob
              user={user}
              isLoading={state.context.isLoading}
              onCreateJob={handleCreateJob}
            />
          }
        />
        <Route
          path="/:jobId"
          element={
            <JobDetails
              user={user}
              data={state.context.jobDetails}
              hiringManagers={state.context.hiringManagers}
              isLoading={state.context.isLoading}
              onFetchDetails={handleFetchDetails}
              onUpdateDetails={handleUpdateDetails}
              onFetchHiringManagers={handleFetchHiringManagers}
              onAssignHiringManagers={handleAssignHiringManagers}
              onCandidateMatch={handleCandidateMatch}
            />
          }
        />
        <Route
          path="/:jobId/candidates"
          element={
            <Candidates
              user={user}
              data={state.context.candidates}
              totalElements={state.context.totalCandidateElements}
              isLoading={state.context.isLoading}
              totalPages={state.context.totalCandidatePages}
              onFetchCandidates={handleFetchCandidates}
              onSendCandidates={handleSendCandidates}
            />
          }
        />
        <Route
          path="/:jobId/candidates/:candidateId"
          element={
            <Candidate
              user={user}
              hiringManagers={state.context.hiringManagers}
              isLoading={state.context.isLoading}
              candidate={state.context.candidate}
              downloadUrl={state.context.candidateDownloadCVUrl}
              candidateFeedback={state.context.candidateFeedback}
              onGetCandidate={handleGetCandidate}
              onAddFeedback={handleAddFeedback}
              onGetFeedback={handleGetFeedback}
              onFetchHiringManagers={handleFetchJobHiringManagers}
              onUpdateCandidateStatus={handleUpdateCandidateStatus}
              onDownloadCandidateCV={handleDownloadCandidateCV}
              onShareCandidate={handleShareCandidate}
            />
          }
        />
      </Routes>
      {contextHolder}
    </>
  );
};

export default JobsRoutes;

import { memo, useCallback, useState, useEffect } from 'react';
import Layout from '../../../../components/layout';
import { User, Customer } from '../../../../fsm/users';

import {
  Button,
  Space,
  Typography,
  Row,
  Divider,
  Table,
  Tag,
  Popconfirm,
} from 'antd';
import type { TablePaginationConfig } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';

import AddUserModal from '../../components/add-user-modal';
import styles from './index.module.css';
import { roleLabelMapping } from '../../utils/role';
import { usePermissions } from '../../../../context/permission.context';

const { Title, Text } = Typography;

interface Props {
  user?: User;
  data: User[];
  customers: Customer[];
  isLoading: boolean;
  totalElements: number;
  onFetchUsers: (pageNumber: number) => void;
  onFetchCustomers: () => void;
  onDeleteUser: (id: number) => void;
  onResetPassword: (email: string) => void;
  onUpdateUser: (data: Partial<User>, id: number) => void;
  onAddUser: (
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    customerName?: number,
  ) => void;
}

const Users = ({
  user,
  data,
  customers,
  isLoading,
  totalElements,
  onFetchUsers,
  onFetchCustomers,
  onAddUser,
  onUpdateUser,
  onDeleteUser,
  onResetPassword,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User | null>(null);
  const {
    canAddNewUser,
    canSeeUserActionsColumn,
    canSeeUserCustomerColumn,
    canSeeAddUserCustomerField,
  } = usePermissions();

  useEffect(() => {
    onFetchUsers(1);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setEditUser(null);
  }, [setIsModalOpen]);

  const handleModalOpen = useCallback(
    (user?: User) => () => {
      if (user) {
        setEditUser(user);
      }
      setIsModalOpen(true);
    },
    [setIsModalOpen, setEditUser],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      onFetchUsers(pagination.current as number);
    },
    [onFetchUsers],
  );

  const confirmDelete = useCallback(
    (id: number) => () => {
      onDeleteUser(id);
    },
    [onDeleteUser],
  );

  const confirmResetPassword = useCallback(
    (email: string) => () => {
      onResetPassword(email);
    },
    [onResetPassword],
  );

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => (
        <Tag>
          {roleLabelMapping[role as keyof typeof roleLabelMapping] || 'Unknown'}
        </Tag>
      ),
    },
    {
      title: 'Full name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (firstName: string, item: User) => (
        <Typography>
          {firstName} {item.lastName}
        </Typography>
      ),
    },
    ...(canSeeUserCustomerColumn
      ? [
          {
            title: 'Customer name',
            dataIndex: 'customerName',
            key: 'customerName',
          },
        ]
      : []),
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Last active',
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (lastLoginAt?: number) => (
        <>
          {lastLoginAt
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(lastLoginAt * 1000))
            : 'No date'}
        </>
      ),
    },
    ...(canSeeUserActionsColumn
      ? [
          {
            title: 'Actions',
            dataIndex: 'listing',
            key: 'listing',
            render: (_e: string, user: User) => (
              <Space size={4} className={styles.actions}>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={handleModalOpen(user)}
                ></Button>
                <Popconfirm
                  title="Are you sure you want to reset password?"
                  description="An email with reset instructions will be sent to the user's email."
                  icon={<ExclamationCircleFilled style={{ color: 'yelow' }} />}
                  onConfirm={confirmResetPassword(user.email)}
                  okText="Reset"
                  cancelText="No"
                >
                  <Button type="text" icon={<ReloadOutlined />}></Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to delete a user?"
                  description="They will no longer have access to the platform."
                  icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
                  onConfirm={confirmDelete(user.id)}
                  okText="Delete"
                  cancelText="No"
                >
                  <Button
                    type="text"
                    icon={
                      <Text type="danger">
                        <DeleteOutlined />
                      </Text>
                    }
                  ></Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
    <Layout user={user}>
      <div className={styles.header}>
        <Space direction="vertical" size={4}>
          <Title level={5} style={{ margin: 0 }}>
            Profile management
          </Title>
          <Text type="secondary">
            Add and manage user profiles for hiring managers and recruiters.
          </Text>
        </Space>
        {canAddNewUser && (
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={handleModalOpen()}
          >
            Add new user
          </Button>
        )}
      </div>
      <div className={styles.wrapper}>
        <Row align={'middle'} style={{ padding: 8 }}>
          <Text style={{ fontSize: 16 }}>Job list</Text>
          <Divider type="vertical" />
          <Text type="secondary" style={{ fontSize: 16 }}>
            Total {totalElements}
          </Text>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          rowClassName={styles.row}
          scroll={{ x: 800 }}
          pagination={{
            total: totalElements,
            pageSize: 10,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </div>
      <AddUserModal
        isOpen={isModalOpen}
        isLoading={isLoading}
        customers={customers}
        user={editUser}
        canSeeAddUserCustomerField={canSeeAddUserCustomerField}
        onClose={handleModalClose}
        onFetchCustomers={onFetchCustomers}
        onSubmit={onAddUser}
        onUpdateUser={onUpdateUser}
      />
    </Layout>
  );
};

export default memo(Users);

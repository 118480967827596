import { memo } from 'react';

import { Modal, Button, Avatar, Typography } from 'antd';
import { RioMessage } from '../../../../../fsm/jobs';

import styles from './index.module.css';

interface Props {
  name?: string;
  data?: RioMessage[];
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
}

const RioHistoryModal = ({ data, name, isOpen, isLoading, onClose }: Props) => {
  return (
    <Modal
      title="Rio communication history"
      open={isOpen}
      width={'55%'}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={
        <Button type="primary" onClick={() => onClose()}>
          Close
        </Button>
      }
    >
      <div className={styles.content}>
        {data?.map((item) => (
          <div
            className={styles.container}
            style={{ alignItems: item.isFromBot ? 'flex-end' : 'flex-start' }}
          >
            <div
              className={styles.header}
              style={{
                flexDirection: item.isFromBot ? undefined : 'row-reverse',
              }}
            >
              <Typography.Text strong>
                {item.isFromBot ? 'Bot' : name}
              </Typography.Text>
              <Avatar
                style={{
                  backgroundColor: item.isFromBot ? '#2B3467' : undefined,
                }}
              >
                {item.isFromBot
                  ? 'Bot'
                  : name
                      ?.split(' ')
                      .map((item) => item.charAt(0))
                      .join('')}
              </Avatar>
            </div>
            <div
              className={
                item.isFromBot ? styles.rioMessage : styles.userMessage
              }
            >
              {item.text}
            </div>
            <Typography.Text type="secondary">
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(item.msgTime * 1000))}
            </Typography.Text>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default memo(RioHistoryModal);

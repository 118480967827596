import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Form, Button, Input, Checkbox, Typography, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import styles from './index.module.css';

const { Title } = Typography;

interface Props {
  isLoading: boolean;
  onLogin: (email: string, password: string) => void;
}

const SignIn = ({ isLoading, onLogin }: Props) => {
  const [form] = Form.useForm();

  const onFinish = useCallback(() => {
    onLogin(form.getFieldValue('email'), form.getFieldValue('password'));
  }, [onLogin, form]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.leftInner}>
          <Title level={isMobile ? 2 : 1} style={{ marginBottom: 48 }}>
            Sign in
          </Title>
          <Form form={form} style={{ width: '100%' }} onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your name',
                },
                { type: 'email' },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 8,
                  message: 'Password must be at least 8 characters long!',
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
                  message:
                    'Password must include uppercase, lowercase, number, and special character!',
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
            <Form.Item>
              <Row justify="space-between">
                <Checkbox>Remember me</Checkbox>
                <Link to="/auth/reset-password">
                  <Typography>Forgot password?</Typography>
                </Link>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={isLoading}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.right}></div>
    </div>
  );
};

export default memo(SignIn);

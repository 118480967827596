import { AxiosError } from 'axios';
import { UserRole } from '../../smallApps/users/utils/role';

export interface User {
  id: number;
  customerId: number;
  customerName: string;
  email: string;
  firstName: string;
  lastLoginAt: number;
  lastName: string;
  role: UserRole;
  hasProfilePicture: boolean;
  profilePictureUrl: string;
  calendarLinkUrl: null | string;
}

export interface Customer {
  id: number;
  logoUrl: null | string;
  name: string;
}

export interface Context {
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  users: User[];
  user?: User;
  customers: Customer[];
  totalElements: number;
  imageURL: string;
  error?: AxiosError;
}

export enum State {
  INITIAL = 'idle',
  FETCH_USERS = 'FETCH_USERS',
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  ADD_USER = 'ADD_USER',
  UPDATE_USER = 'UPDATE_USER',
  USER_UPDATED = 'USER_UPDATED',
  DELETE_USER = 'DELETE_USER',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE',
  GET_USER = 'GET_USER',
  REGISTOR_IMAGE_URL = 'REGISTOR_IMAGE_URL',
  ERROR = 'error',
}

export enum EventType {
  FETCH_USERS = 'FETCH_USERS',
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  ADD_USER = 'ADD_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REGISTOR_IMAGE_URL = 'REGISTOR_IMAGE_URL',
  DELETE_IMAGE = 'DELETE_IMAGE',
  GET_USER = 'GET_USER',
}

export type fetchUsersEvent = {
  type: EventType.FETCH_USERS;
  payload: { pageNumber: number };
};

export type fetchCustomersEvent = {
  type: EventType.FETCH_CUSTOMERS;
};

export type getUserEvent = {
  type: EventType.GET_USER;
  payload: { id: number };
};

export type registorImageUrlEvent = {
  type: EventType.REGISTOR_IMAGE_URL;
  payload: { id: number };
};

export type deleteImageUrlEvent = {
  type: EventType.DELETE_IMAGE;
};

export type addUserEvent = {
  type: EventType.ADD_USER;
  payload: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    customerId?: number;
  };
};

export type updateUserEvent = {
  type: EventType.UPDATE_USER;
  payload: {
    data: Partial<User>;
    id: number;
    file?: File;
  };
};

export type deleteUserEvent = {
  type: EventType.DELETE_USER;
  payload: {
    id: number;
  };
};

export type resetPasswordEvent = {
  type: EventType.RESET_PASSWORD;
  payload: {
    email: string;
  };
};

export type Event =
  | fetchUsersEvent
  | addUserEvent
  | fetchCustomersEvent
  | updateUserEvent
  | deleteUserEvent
  | resetPasswordEvent
  | getUserEvent
  | registorImageUrlEvent
  | deleteImageUrlEvent;

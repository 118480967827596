import { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Modal,
  Button,
  Avatar,
  Space,
  Typography,
  Divider,
  List,
  message,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { HiringManager } from '../../../../../fsm/jobs';

import styles from './index.module.css';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  name?: string;
  hiringManagers: HiringManager[];
  onClose: () => void;
  onFetchHiringManagers: (jobId: number) => void;
  onShareCandidate: (
    jobId: number,
    candidateId: number,
    userId: number,
  ) => void;
}

const ShareProfileModal = ({
  isOpen,
  isLoading,
  hiringManagers,
  name,
  onClose,
  onFetchHiringManagers,
  onShareCandidate,
}: Props) => {
  const { jobId, candidateId } = useParams();

  useEffect(() => {
    if (isOpen && jobId) {
      onFetchHiringManagers(parseInt(jobId));
    }
  }, [isOpen, jobId, onFetchHiringManagers]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        message.success('Link copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy text!');
      });
  };

  const handleShareCandidate = useCallback(
    (userId: number) => () => {
      if (jobId && candidateId) {
        onShareCandidate(parseInt(jobId), parseInt(candidateId), userId);
        onClose();
      }
    },
    [jobId, candidateId, onShareCandidate, onClose],
  );

  return (
    <Modal
      title="Share profile with your team"
      open={isOpen}
      confirmLoading={isLoading}
      onCancel={() => onClose()}
      footer={null}
    >
      <div className={styles.content}>
        <Space size={12} style={{ paddingTop: '20px' }}>
          <Avatar size={64}>
            {name &&
              name
                .split(' ')
                .map((item) => item.charAt(0))
                .join('')}
          </Avatar>
          <Typography.Text strong style={{ fontSize: 20 }}>
            {name}
          </Typography.Text>
        </Space>
        <Divider />
        {!!hiringManagers.length && (
          <>
            <List
              itemLayout="horizontal"
              loading={isLoading}
              dataSource={hiringManagers}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.profilePictureUrl}>
                        {item.firstName.charAt(0)}
                        {item.lastName.charAt(0)}
                      </Avatar>
                    }
                    title={
                      <Typography.Text>
                        {item.firstName} {item.lastName}
                      </Typography.Text>
                    }
                    description={
                      <Typography.Text type="secondary">
                        {item.role}
                      </Typography.Text>
                    }
                  />
                  <Typography.Link onClick={handleShareCandidate(item.id)}>
                    Share
                  </Typography.Link>
                </List.Item>
              )}
            />
            <Divider />
          </>
        )}
        <Button block icon={<CopyOutlined />} size="large" onClick={handleCopy}>
          Copy scorecard link
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ShareProfileModal);

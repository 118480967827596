import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import { authFSM, EventType, State } from '../../fsm/auth';

import { message } from 'antd';

import Login from './pages/login';
import ResetPassword from './pages/reset-password';
import { useCallback } from 'react';

const AuthRoutes = () => {
  const [state, send] = useMachine(authFSM);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.value.match(State.ERROR)) {
      messageApi.open({
        type: 'error',
        content: state.context.error?.message,
      });
    }
    if (state.value.match(State.EMAIL_SENT)) {
      messageApi.open({
        type: 'success',
        content: 'Password reset link sent to your email.',
      });
    }
  }, [state.value]);

  const handleLogin = useCallback(
    (email: string, password: string) => {
      send({
        type: EventType.LOGIN,
        payload: { email, password, navigate },
      });
    },
    [send, navigate],
  );

  const handleResetPasswordRequest = useCallback(
    (email: string) => {
      send({
        type: EventType.RESET_PASSWORD_REQUEST,
        payload: { email },
      });
    },
    [send],
  );

  const handleResetPassword = useCallback(
    (password: string, token: string) => {
      send({
        type: EventType.RESET_PASSWORD,
        payload: { password, token, navigate },
      });
    },
    [send, navigate],
  );

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <Login isLoading={state.context.isLoading} onLogin={handleLogin} />
          }
        />
        <Route
          path="/reset-password"
          element={
            <ResetPassword
              isLoading={state.context.isLoading}
              resetForm={!!state.value.match(State.EMAIL_SENT)}
              onResetPasswordRequest={handleResetPasswordRequest}
              onResetPassword={handleResetPassword}
            />
          }
        />
      </Routes>
      {contextHolder}
    </>
  );
};

export default AuthRoutes;

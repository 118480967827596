export enum UserRole {
  ADMIN = 'ADMIN',
  HIRING_MANAGER = 'HIRING_MANAGER',
  RECRUITER = 'RECRUITER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export const roleLabelMapping = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.HIRING_MANAGER]: 'Hiring Manager',
  [UserRole.RECRUITER]: 'Recruiter',
};

export enum Permission {
  CAN_ADD_NEW_JOB = 'CAN_ADD_NEW_JOB',
  CAN_SEE_JOB_COMPANY_COLUMN = 'CAN_SEE_JOB_COMPANY_COLUMN',
  CAN_SEE_JOB_RECRUITER_COLUMN = 'CAN_SEE_JOB_RECRUITER_COLUMN',
  CAN_SEE_JOB_HIRING_MANAGER_COLUMN = 'CAN_SEE_JOB_HIRING_MANAGER_COLUMN',
  CAN_SEE_JOB_CUSTOMER_NAME_COLUMN = 'CAN_SEE_JOB_CUSTOMER_NAME_COLUMN',
  CAN_SEE_JOB_POST_CUSTOMER_FIELD = 'CAN_SEE_JOB_POST_CUSTOMER_FIELD',
  CAN_SEE_JOB_ASSIGN_HM_CARD = 'CAN_SEE_JOB_ASSIGN_HM_CARD',
  CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON = 'CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON',

  CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON = 'CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON',
  CAN_SEE_CANDIDATE_ACTION_COLUMN = 'CAN_SEE_CANDIDATE_ACTION_COLUMN',
  CAN_SEE_CANDIDATE_REJECT_BUTTON = 'CAN_SEE_CANDIDATE_REJECT_BUTTON',
  CAN_SEE_CANDIDATE_VIEW_HM_FEEDBACK_BUTTON = 'CAN_SEE_CANDIDATE_VIEW_HM_FEEDBACK_BUTTON',
  CAN_SEE_CANDIDATE_ADD_FEEDBACK_BUTTON = 'CAN_SEE_CANDIDATE_ADD_FEEDBACK_BUTTON',
  CAN_SEE_CANDIDATE_NEXT_STAGE_BUTTON = 'CAN_SEE_CANDIDATE_NEXT_STAGE_BUTTON',

  CAN_ADD_NEW_USER = 'CAN_ADD_NEW_USER',
  CAN_SEE_ADD_USER_CUSTOMER_FIELD = 'CAN_SEE_ADD_USER_CUSTOMER_FIELD',
  CAN_SEE_USER_CUSTOMER_COLUMN = 'CAN_SEE_USER_CUSTOMER_COLUMN',
  CAN_SEE_USER_ACTIONS_COLUMN = 'CAN_SEE_USER_ACTIONS_COLUMN',

  CAN_SEE_USER_MANAGMENT_NAVIGATION_BUTTON = 'CAN_SEE_USER_MANAGMENT_NAVIGATION_BUTTON',
  CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON = 'CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON',
  CAN_SEE_CANDIDATES_ASSESSMENT_NAVIGATION_BUTTON = 'CAN_SEE_CANDIDATES_ASSESSMENT_NAVIGATION_BUTTON',
}

export const permissions = {
  [UserRole.ADMIN]: [
    Permission.CAN_ADD_NEW_JOB,
    Permission.CAN_SEE_JOB_HIRING_MANAGER_COLUMN,
    Permission.CAN_SEE_JOB_RECRUITER_COLUMN,
    Permission.CAN_ADD_NEW_USER,
    Permission.CAN_SEE_USER_ACTIONS_COLUMN,
    Permission.CAN_SEE_USER_MANAGMENT_NAVIGATION_BUTTON,
    Permission.CAN_SEE_JOB_ASSIGN_HM_CARD,
    Permission.CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON,
    Permission.CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON,
    Permission.CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON,
  ],
  [UserRole.HIRING_MANAGER]: [
    Permission.CAN_SEE_JOB_RECRUITER_COLUMN,
    Permission.CAN_SEE_CANDIDATE_ACTION_COLUMN,
    Permission.CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON,
    Permission.CAN_SEE_CANDIDATE_ADD_FEEDBACK_BUTTON,
    Permission.CAN_SEE_CANDIDATES_ASSESSMENT_NAVIGATION_BUTTON,
  ],
  [UserRole.RECRUITER]: [
    Permission.CAN_ADD_NEW_JOB,
    Permission.CAN_SEE_JOB_COMPANY_COLUMN,
    Permission.CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON,
    Permission.CAN_SEE_MY_PROFILE_NAVIGATION_BUTTON,
    Permission.CAN_SEE_CANDIDATE_ACTION_COLUMN,
    Permission.CAN_SEE_CANDIDATE_REJECT_BUTTON,
    Permission.CAN_SEE_CANDIDATE_VIEW_HM_FEEDBACK_BUTTON,
    Permission.CAN_SEE_CANDIDATE_NEXT_STAGE_BUTTON,
    Permission.CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON,
    Permission.CAN_SEE_JOB_ASSIGN_HM_CARD,
  ],
  [UserRole.SUPER_ADMIN]: [
    Permission.CAN_ADD_NEW_JOB,
    Permission.CAN_SEE_JOB_CUSTOMER_NAME_COLUMN,
    Permission.CAN_SEE_JOB_POST_CUSTOMER_FIELD,
    Permission.CAN_ADD_NEW_USER,
    Permission.CAN_SEE_ADD_USER_CUSTOMER_FIELD,
    Permission.CAN_SEE_USER_CUSTOMER_COLUMN,
    Permission.CAN_SEE_USER_ACTIONS_COLUMN,
    Permission.CAN_SEE_USER_MANAGMENT_NAVIGATION_BUTTON,
    Permission.CAN_SEE_CANDIDATE_SEND_EMAIL_BUTTON,
    Permission.CAN_SEE_JOB_ASSIGN_HM_CARD,
    Permission.CAN_SEE_CANDIDATE_ACTION_COLUMN,
    Permission.CAN_SEE_CANDIDATE_REJECT_BUTTON,
    Permission.CAN_SEE_CANDIDATE_VIEW_HM_FEEDBACK_BUTTON,
    Permission.CAN_SEE_CANDIDATE_NEXT_STAGE_BUTTON,
    Permission.CAN_SEE_JOB_CANDIDATE_MATCH_BUTTON,
  ],
};

export const roleOptions = [
  {
    label: roleLabelMapping[UserRole.ADMIN],
    value: UserRole.ADMIN,
  },
  {
    label: roleLabelMapping[UserRole.HIRING_MANAGER],
    value: UserRole.HIRING_MANAGER,
  },
  {
    label: roleLabelMapping[UserRole.RECRUITER],
    value: UserRole.RECRUITER,
  },
];

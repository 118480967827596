import { memo, useCallback, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import styles from './index.module.css';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const MyEditor = ({ value, onChange }: Props) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);

  const handleChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <div className={styles.wrapper}>
      <Editor
        apiKey="ey173m3hfdhf3m03tu4itc1e5dyhs88zmfs2y51oqw12o58g"
        onInit={(_evt, editor) => (editorRef.current = editor)}
        onEditorChange={handleChange}
        value={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
};

export default memo(MyEditor);

export enum Step {
  JobDetails = 0,
  SkillsEducation = 1,
  InvitationSettings = 2,
}

export enum WorkplaceType {
  HYBRID = 'HYBRID',
  REMOTE = 'REMOTE',
  ONSITE = 'ONSITE',
}

import { memo, useState, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import {
  Button,
  Space,
  Flex,
  Divider,
  Typography,
  Tag,
  Dropdown,
  Menu,
} from 'antd';
import {
  ScheduleOutlined,
  EditOutlined,
  DownOutlined,
} from '@ant-design/icons';

import styles from './index.module.css';
import { academicDegreeMapping } from '../../../../components/common/education-list-maker';
import { Job, JobPayload } from '../../../../fsm/jobs';
import Modal from './Modal';

const { Title, Text } = Typography;

const grayAI = `${process.env.PUBLIC_URL}/images/gray-ai.svg`;
const emptyStateImg = `${process.env.PUBLIC_URL}/images/emty-state.svg`;

interface Props {
  data: Job;
  onUpdateDetails: (job: JobPayload) => void;
}

const SkillsEducationEdit = ({ data, onUpdateDetails }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const emptyState = useMemo(() => {
    return (
      !data.requiredSkills.length &&
      !data.preferredSkills.length &&
      !data.minTotalExperience &&
      !data.maxTotalExperience &&
      !data.minIndustryExperience &&
      !data.maxIndustryExperience &&
      !data.hasManagementExperience &&
      !data.educationList.length &&
      !data.certification?.certifications.length &&
      !data.certification?.licensures.length
    );
  }, [data]);

  return (
    <div
      className={emptyState ? styles.body : `${styles.body} ${styles.active}`}
    >
      <Flex align="center" justify="space-between">
        <Space>
          <ScheduleOutlined />
          <Title level={4} style={{ margin: 0 }}>
            Skills & Experience
          </Title>
        </Space>
        <Button
          type="default"
          shape="round"
          icon={<EditOutlined />}
          onClick={handleModalOpen}
        >
          {isMobile ? '' : 'Edit skills & experience'}
        </Button>
      </Flex>
      <Divider />
      {emptyState ? (
        <div className={styles.emptyContainer}>
          <img src={emptyStateImg} />
          <Text type="secondary">
            No required skills and experience identified. Please add the
            information.
          </Text>
        </div>
      ) : (
        <>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <Space>
                <Text strong>Required skills</Text>
                <img src={grayAI} />
              </Space>
              {!!data.requiredSkills.length ? (
                <div>
                  {data.requiredSkills.map((item) => (
                    <Tag key={item}>{item}</Tag>
                  ))}
                </div>
              ) : (
                <Text type="secondary">No required skills identified</Text>
              )}
            </div>
            <div className={styles.card}>
              <Space>
                <Text strong>Preferred skills</Text>
                <img src={grayAI} />
              </Space>
              {!!data.preferredSkills.length ? (
                <div>
                  {data.preferredSkills.map((item) => (
                    <Tag key={item}>{item}</Tag>
                  ))}
                </div>
              ) : (
                <Text type="secondary">No preferred skills identified</Text>
              )}
            </div>
            <div className={styles.card}>
              <Text strong>Experience</Text>
              <Space direction="vertical" size={0} style={{ width: '100%' }}>
                <Text strong>
                  Total: {data.minTotalExperience}-{data.maxTotalExperience}{' '}
                  years
                </Text>
                <Text strong>
                  Industry: {data.minIndustryExperience}-
                  {data.maxIndustryExperience} years
                </Text>
                {data.hasManagementExperience && (
                  <Text type="secondary">Has management experience</Text>
                )}
              </Space>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <Text strong>Major</Text>
              <Text>
                {!!data.educationList.length ? (
                  <>
                    {data.educationList[0].academicDegree &&
                      academicDegreeMapping[
                        data.educationList[0]
                          .academicDegree as keyof typeof academicDegreeMapping
                      ]}
                    {data.educationList[0].major &&
                      ` in ${data.educationList[0].major}`}
                  </>
                ) : (
                  <Text type="secondary">N/A</Text>
                )}
              </Text>
              {data.educationList.length > 1 && (
                <Dropdown
                  overlay={
                    <Menu>
                      {data.educationList.map((item, index) => (
                        <Menu.Item
                          className={styles['custom-dropdown-menu-item']}
                          key={index}
                        >
                          {item.academicDegree &&
                            academicDegreeMapping[
                              item.academicDegree as keyof typeof academicDegreeMapping
                            ]}
                          {item.major && ` in ${item.major}`}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    +{data.educationList.length - 1} more{' '}
                    <DownOutlined style={{ fontSize: 12 }} />
                  </a>
                </Dropdown>
              )}
            </div>
            <div className={styles.card}>
              <Text strong>Certifications</Text>
              <div>
                {!!data.certification?.certifications.length ? (
                  data.certification?.certifications.map((item) => (
                    <Tag key={item}>{item}</Tag>
                  ))
                ) : (
                  <Text type="secondary">N/A</Text>
                )}
              </div>
            </div>
            <div className={styles.card}>
              <Text strong>Licensures</Text>
              <div>
                {!!data.certification?.licensures.length ? (
                  data.certification?.licensures.map((item) => (
                    <Tag key={item}>{item}</Tag>
                  ))
                ) : (
                  <Text type="secondary">N/A</Text>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        data={data}
        isOpen={isModalOpen}
        isLoading={false}
        onClose={handleModalClose}
        onSubmit={onUpdateDetails}
      />
    </div>
  );
};

export default memo(SkillsEducationEdit);

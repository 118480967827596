import { memo, ReactNode } from 'react';
import { Breadcrumb } from 'antd';

import styles from './index.module.css';

interface Props {
  items?: Array<{ title: string | ReactNode }>;
  renderRight?: ReactNode;
  renderLeft?: ReactNode;
}

const AppBreadcrumb = ({ items, renderRight, renderLeft }: Props) => {
  return (
    <div className={styles.wrapper}>
      {items && <Breadcrumb items={items} />}
      {renderLeft && renderLeft}
      {renderRight && renderRight}
    </div>
  );
};

export default memo(AppBreadcrumb);

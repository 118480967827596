import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  Table,
  Button,
  Row,
  Divider,
  Typography,
  Tag,
  Space,
  Tooltip,
} from 'antd';
import type { TablePaginationConfig, TableProps } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';
import { SendOutlined, FilterOutlined } from '@ant-design/icons';

import Breadcrumb from '../../../../components/common/breadcrumb';
import Layout from '../../../../components/layout';
import { Candidate, Status } from '../../../../fsm/jobs/jobs.fsm.types';
import styles from './index.module.css';

import ShareModal from '../../components/share-modal';

import { usePermissions } from '../../../../context/permission.context';
import { User } from '../../../../fsm/users';
import Filters from '../../components/filters';

const { Text } = Typography;

const orderMapping = {
  ascend: 'ASC',
  descend: 'DESC',
};

const STATUS_LABEL = {
  NEW: 'New',
  OPEN: 'Open',
  REVIEWED: 'Reviewed',
  INVITATION_SENT: 'Invitation sent',
  RIO_SCREENING: 'Rio screening',
  ALT_INTERVIEW: 'Alt interview',
  HM_REVIEW: 'HM review',
  REJECTED: 'Rejected',
  HIRED: 'Hired',
  NOT_INTERESTED: 'Not interested',
};

type TableRowSelection<T extends object = object> =
  TableProps<T>['rowSelection'];

interface Props {
  user?: User;
  data: Candidate[];
  isLoading: boolean;
  totalElements: number;
  totalPages: number;
  onFetchCandidates: (
    jobId: number,
    pageNumber: number,
    pageSize: number,
    fitScore?: number,
    statuses?: Status[],
    hasMandatorySkills?: boolean,
    hasMandatoryTitleMatch?: boolean,
    maxDistance?: number,
    candidatesWithNoLocation?: boolean,
    isApplicant?: boolean,
    sort?: string,
    order?: string,
  ) => void;
  onSendCandidates: (
    jobId: number,
    candidateIds: React.Key[],
    emails: string[],
    emailBody: string,
  ) => void;
}

const breadcrumbItems = [
  {
    title: <Link to="/jobs">Job list</Link>,
  },
  {
    title: 'Candidates',
  },
];

const Candidates = ({
  user,
  data,
  isLoading,
  totalElements,
  totalPages,
  onFetchCandidates,
  onSendCandidates,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [sortOrder] = useState<string | undefined>('DESC');
  const [sortField] = useState<string | undefined>('fitScore');
  const [pageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(isMobile ? 6 : 10);
  const { jobId } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filters, setFilters] = useState<{
    fitScore?: number;
    statuses?: Status[];
    hasMandatorySkills?: boolean;
    hasMandatoryTitleMatch?: boolean;
    maxDistance?: number;
    candidatesWithNoLocation?: boolean;
    isApplicant?: boolean;
  }>({
    fitScore: undefined,
    statuses: undefined,
    hasMandatorySkills: undefined,
    hasMandatoryTitleMatch: undefined,
    maxDistance: undefined,
    candidatesWithNoLocation: undefined,
    isApplicant: undefined,
  });
  const { canSeeCandidateSendEmailButton, canSeeCandidateActionColumn } =
    usePermissions();

  useEffect(() => {
    if (jobId) {
      onFetchCandidates(
        parseInt(jobId),
        pageNumber,
        pageSize,
        filters.fitScore,
        filters.statuses,
        filters.hasMandatorySkills,
        filters.hasMandatoryTitleMatch,
        filters.maxDistance,
        filters.candidatesWithNoLocation,
        filters.isApplicant,
        sortField,
        sortOrder,
      );
    }
  }, [jobId, sortOrder, sortField, filters, sortField, sortOrder]);

  const list = useMemo(() => {
    return data.map((item) => ({ ...item, key: item.id }));
  }, [data]);

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      _: Record<string, any>,
      sorter: SorterResult<Candidate> | SorterResult<Candidate>[],
    ) => {
      const currentSorter = Array.isArray(sorter) ? sorter[0] : sorter;

      jobId &&
        onFetchCandidates(
          parseInt(jobId),
          (pagination.current as number) || pageNumber,
          pageSize,
          filters.fitScore,
          filters.statuses,
          filters.hasMandatorySkills,
          filters.hasMandatoryTitleMatch,
          filters.maxDistance,
          filters.candidatesWithNoLocation,
          filters.isApplicant,
          currentSorter.field as string,
          orderMapping[currentSorter.order as keyof typeof orderMapping],
        );
    },
    [jobId, pageSize, filters, pageNumber, onFetchCandidates],
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<Candidate> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleModalClose = useCallback(
    (emptySelectedRows?: boolean) => {
      setIsModalOpen(false);
      if (emptySelectedRows) {
        setSelectedRowKeys([]);
      }
    },
    [setIsModalOpen],
  );

  const handleFiltersOpen = useCallback(() => {
    setIsFiltersOpen(true);
  }, [setIsFiltersOpen]);

  const handleFiltersClose = useCallback(() => {
    setIsFiltersOpen(false);
  }, [setIsFiltersOpen]);

  const handleFiltersApply = useCallback(
    (filters: {
      fitScore?: number;
      statuses?: Status[];
      hasMandatorySkills?: boolean;
      hasMandatoryTitleMatch?: boolean;
      maxDistance?: number;
      candidatesWithNoLocation?: boolean;
      isApplicant?: boolean;
    }) => {
      setFilters(filters);
      handleFiltersClose();
    },
    [jobId, handleFiltersClose],
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, row: Candidate) => (
        <Link to={canSeeCandidateActionColumn ? row.id.toString() : ''}>
          {name}
        </Link>
      ),
    },
    {
      title: 'Fitscore',
      dataIndex: 'fitScore',
      key: 'fitScore',
      render: (fitscore: number, item: Candidate) => (
        <Tooltip placement="top" title={item.skills.join(', ')}>
          <div className={styles.card}>
            <div className={styles.cardScore}>{fitscore} </div>{' '}
            <Divider type="vertical" />
            <div className={styles.cardInfo}>
              {item.skills.length} Skill matches
            </div>
          </div>
        </Tooltip>
      ),
      sorter: true,
    },
    {
      title: 'Company',
      dataIndex: 'employerName',
      key: 'employerName',
    },
    {
      title: 'Last position',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let color;
        if (status === 'ACTIVE') {
          color = 'green';
        } else if (status === 'REJECTED' || status === 'NOT_INTERESTED') {
          color = 'red';
        } else if (status === 'NOT_REVIEWED') {
          color = undefined;
        } else {
          color = 'purple';
        }
        return (
          <Tag color={color}>
            {STATUS_LABEL[status as keyof typeof STATUS_LABEL]}
          </Tag>
        );
      },
    },
    {
      title: 'Last activity',
      dataIndex: 'lastActivityAt',
      key: 'lastActivityAt',
      render: (postedAt?: number) => (
        <div className={styles.nowrap}>
          {postedAt
            ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(postedAt * 1000))
            : 'No date'}
        </div>
      ),
      sorter: true,
    },
    ...(canSeeCandidateActionColumn
      ? [
          {
            title: 'Actions',
            dataIndex: 'listing',
            key: 'listing',
            render: (_a: unknown, { id }: Candidate) => (
              <Link className={styles.nowrap} to={id.toString()}>
                View profile
              </Link>
            ),
          },
        ]
      : []),
  ];

  return (
    <Layout user={user}>
      <Breadcrumb
        items={breadcrumbItems}
        renderRight={
          <Button type="primary" shape="round">
            <Link to={`/jobs/${jobId}`}>View job post</Link>
          </Button>
        }
      />
      <div className={styles.wrapper}>
        <Row
          align={'middle'}
          justify={'space-between'}
          style={{ padding: isMobile ? '8px 0px' : 8 }}
        >
          <div>
            <Text style={{ fontSize: 16 }}>
              {isMobile ? 'Candidates' : 'Candidates for Product manager role'}
            </Text>
            <Divider type="vertical" />
            <Text type="secondary" style={{ fontSize: 16 }}>
              Total {totalElements}
            </Text>
          </div>
          <div>
            <Space>
              {canSeeCandidateSendEmailButton && (
                <Button
                  type="text"
                  icon={isMobile ? undefined : <SendOutlined />}
                  disabled={!selectedRowKeys.length}
                  onClick={handleModalOpen}
                >
                  Share
                </Button>
              )}
              <Button icon={<FilterOutlined />} onClick={handleFiltersOpen}>
                Filters
              </Button>
            </Space>
          </div>
        </Row>
        <Table
          dataSource={list}
          columns={columns}
          loading={isLoading}
          scroll={{ x: 800 }}
          pagination={
            totalPages > 1 && {
              total: totalElements,
              pageSize: pageSize,
              showSizeChanger: false,
            }
          }
          rowSelection={
            canSeeCandidateSendEmailButton ? rowSelection : undefined
          }
          onChange={handleTableChange}
        />
      </div>
      <ShareModal
        isOpen={isModalOpen}
        isLoading={isLoading}
        candidateIds={selectedRowKeys}
        onClose={handleModalClose}
        onSendCandidates={onSendCandidates}
      />
      <Filters
        isOpen={isFiltersOpen}
        onClose={handleFiltersClose}
        onApply={handleFiltersApply}
      />
    </Layout>
  );
};

export default memo(Candidates);
